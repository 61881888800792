import * as React from 'react';
import { Link } from 'react-router-dom';
import { replaceTofu } from '@/utils/string';

export default function ShippingInfo(props) {
  return (
    <div>
      {props.isBoughtUser ? (
        <h3>おめでとうございます！落札できました</h3>
      ) : (
        <h3>おめでとうございます！落札されました</h3>
      )}

      <div className="shipping_message">
        {props.isBoughtUser ? (
          <div>
            下記の住所に送付されます。
            <p className="bought_message">
              ※この送付先は出品者に対してのみ表示されます。
              <br />
              ※チャット上で送付先を変更し、商品が届かない場合、Vegetable
              Saleは責任を負いません。
            </p>
          </div>
        ) : (
          <>
            <Link to={`/users/${props.boughtUserId}`}>
              <span>{replaceTofu(props.boughtUserName)}</span>
            </Link>
            さんに商品を発送してください。
          </>
        )}
      </div>
      <div className="buyer_shipping_info">
        【送付先】
        <table>
          <tr>
            <th>名前：</th>
            <td>{replaceTofu(props.userShippingData.full_name) || '-'}</td>
          </tr>
          <tr>
            <th>郵便番号：</th>
            <td>{props.userShippingData.post_number || '-'}</td>
          </tr>
          <tr>
            <th>住所：</th>
            <td>{props.userShippingData.address || '-'}</td>
          </tr>
          <tr>
            <th>携帯電話番号：</th>
            <td>{props.userShippingData.phone_number || '-'}</td>
          </tr>
          <tr>
            <th>配達指定時間：</th>
            <td>
              {(() => {
                switch (props.userShippingData.delivery_time) {
                  case 0:
                    return '-';
                  case 1:
                    return '指定しない';
                  case 2:
                    return '午前中';
                  case 3:
                    return '14～16時';
                  case 4:
                    return '16～18時';
                  case 5:
                    return '18～20時';
                  case 6:
                    return '19～21時';
                  default:
                    return '-';
                }
              })()}
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
}
