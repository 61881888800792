export default {
  北海道地方: {
    北海道: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    青森県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    岩手県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    宮城県: {
      60: '1,480',
      80: '1,700',
      100: '2,050',
      120: '2,600',
    },
    秋田県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    山形県: {
      60: '1,480',
      80: '1,700',
      100: '2,050',
      120: '2,600',
    },
    福島県: {
      60: '1,480',
      80: '1,700',
      100: '2,050',
      120: '2,600',
    },
    茨城県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    栃木県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    群馬県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    埼玉県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    千葉県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    東京都: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    神奈川県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    新潟県: {
      60: '1,700',
      80: '1,920',
      100: '2,270',
      120: '2,820',
    },
    富山県: {
      60: '1,700',
      80: '1,920',
      100: '2,270',
      120: '2,820',
    },
    石川県: {
      60: '1,700',
      80: '1,920',
      100: '2,270',
      120: '2,820',
    },
    福井県: {
      60: '1,700',
      80: '1,920',
      100: '2,270',
      120: '2,820',
    },
    山梨県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    長野県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    岐阜県: {
      60: '1,700',
      80: '1,920',
      100: '2,270',
      120: '2,820',
    },
    静岡県: {
      60: '1,700',
      80: '1,920',
      100: '2,270',
      120: '2,820',
    },
    愛知県: {
      60: '1,700',
      80: '1,920',
      100: '2,270',
      120: '2,820',
    },
    三重県: {
      60: '1,700',
      80: '1,920',
      100: '2,270',
      120: '2,820',
    },
    滋賀県: {
      60: '1,920',
      80: '2,140',
      100: '2,490',
      120: '3,040',
    },
    京都府: {
      60: '1,920',
      80: '2,140',
      100: '2,490',
      120: '3,040',
    },
    大阪府: {
      60: '1,920',
      80: '2,140',
      100: '2,490',
      120: '3,040',
    },
    兵庫県: {
      60: '1,920',
      80: '2,140',
      100: '2,490',
      120: '3,040',
    },
    奈良県: {
      60: '1,920',
      80: '2,140',
      100: '2,490',
      120: '3,040',
    },
    和歌山県: {
      60: '1,920',
      80: '2,140',
      100: '2,490',
      120: '3,040',
    },
    鳥取県: {
      60: '2,030',
      80: '2,250',
      100: '2,600',
      120: '3,150',
    },
    島根県: {
      60: '2,030',
      80: '2,250',
      100: '2,600',
      120: '3,150',
    },
    岡山県: {
      60: '2,030',
      80: '2,250',
      100: '2,600',
      120: '3,150',
    },
    広島県: {
      60: '2,030',
      80: '2,250',
      100: '2,600',
      120: '3,150',
    },
    山口県: {
      60: '2,030',
      80: '2,250',
      100: '2,600',
      120: '3,150',
    },
    徳島県: {
      60: '2,030',
      80: '2,250',
      100: '2,600',
      120: '3,150',
    },
    香川県: {
      60: '2,030',
      80: '2,250',
      100: '2,600',
      120: '3,150',
    },
    愛媛県: {
      60: '2,030',
      80: '2,250',
      100: '2,600',
      120: '3,150',
    },
    高知県: {
      60: '2,030',
      80: '2,250',
      100: '2,600',
      120: '3,150',
    },
    福岡県: {
      60: '2,250',
      80: '2,470',
      100: '2,820',
      120: '3,370',
    },
    佐賀県: {
      60: '2,250',
      80: '2,470',
      100: '2,820',
      120: '3,370',
    },
    長崎県: {
      60: '2,250',
      80: '2,470',
      100: '2,820',
      120: '3,370',
    },
    熊本県: {
      60: '2,250',
      80: '2,470',
      100: '2,820',
      120: '3,370',
    },
    大分県: {
      60: '2,250',
      80: '2,470',
      100: '2,820',
      120: '3,370',
    },
    宮崎県: {
      60: '2,250',
      80: '2,470',
      100: '2,820',
      120: '3,370',
    },
    鹿児島県: {
      60: '2,250',
      80: '2,470',
      100: '2,820',
      120: '3,370',
    },
    沖縄県: {
      60: '2,250',
      80: '2,800',
      100: '3,480',
      120: '4,360',
    },
  },
  東北地方: {
    北海道: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    青森県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    岩手県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    宮城県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    秋田県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    山形県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    福島県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    茨城県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    栃木県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    群馬県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    埼玉県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    千葉県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    東京都: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    神奈川県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    新潟県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    富山県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    石川県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    福井県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    山梨県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    長野県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    岐阜県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    静岡県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    愛知県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    三重県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    滋賀県: {
      60: '1,480',
      80: '1,700',
      100: '2,050',
      120: '2,600',
    },
    京都府: {
      60: '1,480',
      80: '1,700',
      100: '2,050',
      120: '2,600',
    },
    大阪府: {
      60: '1,480',
      80: '1,700',
      100: '2,050',
      120: '2,600',
    },
    兵庫県: {
      60: '1,480',
      80: '1,700',
      100: '2,050',
      120: '2,600',
    },
    奈良県: {
      60: '1,480',
      80: '1,700',
      100: '2,050',
      120: '2,600',
    },
    和歌山県: {
      60: '1,480',
      80: '1,700',
      100: '2,050',
      120: '2,600',
    },
    鳥取県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    島根県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    岡山県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    広島県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    山口県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    徳島県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    香川県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    愛媛県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    高知県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    福岡県: {
      60: '1,810',
      80: '2,030',
      100: '2,380',
      120: '2,930',
    },
    佐賀県: {
      60: '1,810',
      80: '2,030',
      100: '2,380',
      120: '2,930',
    },
    長崎県: {
      60: '1,810',
      80: '2,030',
      100: '2,380',
      120: '2,930',
    },
    熊本県: {
      60: '1,810',
      80: '2,030',
      100: '2,380',
      120: '2,930',
    },
    大分県: {
      60: '1,810',
      80: '2,030',
      100: '2,380',
      120: '2,930',
    },
    宮崎県: {
      60: '1,810',
      80: '2,030',
      100: '2,380',
      120: '2,930',
    },
    鹿児島県: {
      60: '1,810',
      80: '2,030',
      100: '2,380',
      120: '2,930',
    },
    沖縄県: {
      60: '1,920',
      80: '2,470',
      100: '3,150',
      120: '4,030',
    },
  },
  関東地方: {
    北海道: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    青森県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    岩手県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    宮城県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    秋田県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    山形県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    福島県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    茨城県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    栃木県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    群馬県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    埼玉県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    千葉県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    東京都: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    神奈川県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    新潟県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    富山県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    石川県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    福井県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    山梨県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    長野県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    岐阜県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    静岡県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    愛知県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    三重県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    滋賀県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    京都府: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    大阪府: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    兵庫県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    奈良県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    和歌山県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    鳥取県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    島根県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    岡山県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    広島県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    山口県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    徳島県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    香川県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    愛媛県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    高知県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    福岡県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    佐賀県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    長崎県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    熊本県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    大分県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    宮崎県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    鹿児島県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    沖縄県: {
      60: '1,590',
      80: '2,140',
      100: '2,820',
      120: '3,700',
    },
  },
  中部地方: {
    北海道: {
      60: '1,700',
      80: '1,920',
      100: '2,270',
      120: '2,820',
    },
    青森県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    岩手県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    宮城県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    秋田県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    山形県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    福島県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    茨城県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    栃木県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    群馬県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    埼玉県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    千葉県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    東京都: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    神奈川県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    新潟県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    富山県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    石川県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    福井県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    山梨県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    長野県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    岐阜県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    静岡県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    愛知県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    三重県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    滋賀県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    京都府: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    大阪府: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    兵庫県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    奈良県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    和歌山県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    鳥取県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    島根県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    岡山県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    広島県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    山口県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    徳島県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    香川県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    愛媛県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    高知県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    福岡県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    佐賀県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    長崎県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    熊本県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    大分県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    宮崎県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    鹿児島県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    沖縄県: {
      60: '1,590',
      80: '2,140',
      100: '2,820',
      120: '3,700',
    },
  },
  近畿地方: {
    北海道: {
      60: '1,920',
      80: '2,140',
      100: '2,490',
      120: '3,040',
    },
    青森県: {
      60: '1,480',
      80: '1,700',
      100: '2,050',
      120: '2,600',
    },
    岩手県: {
      60: '1,480',
      80: '1,700',
      100: '2,050',
      120: '2,600',
    },
    宮城県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    秋田県: {
      60: '1,480',
      80: '1,700',
      100: '2,050',
      120: '2,600',
    },
    山形県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    福島県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    茨城県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    栃木県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    群馬県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    埼玉県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    千葉県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    東京都: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    神奈川県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    新潟県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    富山県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    石川県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    福井県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    山梨県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    長野県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    岐阜県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    静岡県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    愛知県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    三重県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    滋賀県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    京都府: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    大阪府: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    兵庫県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    奈良県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    和歌山県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    鳥取県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    島根県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    岡山県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    広島県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    山口県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    徳島県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    香川県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    愛媛県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    高知県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    福岡県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    佐賀県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    長崎県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    熊本県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    大分県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    宮崎県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    鹿児島県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    沖縄県: {
      60: '1,590',
      80: '2,140',
      100: '2,820',
      120: '3,700',
    },
  },
  '中国・四国地方': {
    北海道: {
      60: '2,030',
      80: '2,250',
      100: '2,600',
      120: '3,150',
    },
    青森県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    岩手県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    宮城県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    秋田県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    山形県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    福島県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    茨城県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    栃木県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    群馬県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    埼玉県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    千葉県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    東京都: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    神奈川県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    新潟県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    富山県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    石川県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    福井県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    山梨県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    長野県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    岐阜県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    静岡県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    愛知県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    三重県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    滋賀県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    京都府: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    大阪府: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    兵庫県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    奈良県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    和歌山県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    鳥取県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    島根県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    岡山県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    広島県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    山口県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    徳島県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    香川県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    愛媛県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    高知県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    福岡県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    佐賀県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    長崎県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    熊本県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    大分県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    宮崎県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    鹿児島県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    沖縄県: {
      60: '1,590',
      80: '2,140',
      100: '2,820',
      120: '3,700',
    },
  },
  九州地方: {
    北海道: {
      60: '2,250',
      80: '2,470',
      100: '2,820',
      120: '3,370',
    },
    青森県: {
      60: '1,810',
      80: '2,030',
      100: '2,380',
      120: '2,930',
    },
    岩手県: {
      60: '1,810',
      80: '2,030',
      100: '2,380',
      120: '2,930',
    },
    宮城県: {
      60: '1,810',
      80: '2,030',
      100: '2,380',
      120: '2,930',
    },
    秋田県: {
      60: '1,810',
      80: '2,030',
      100: '2,380',
      120: '2,930',
    },
    山形県: {
      60: '1,810',
      80: '2,030',
      100: '2,380',
      120: '2,930',
    },
    福島県: {
      60: '1,810',
      80: '2,030',
      100: '2,380',
      120: '2,930',
    },
    茨城県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    栃木県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    群馬県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    埼玉県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    千葉県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    東京都: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    神奈川県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    新潟県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    富山県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    石川県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    福井県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    山梨県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    長野県: {
      60: '1,590',
      80: '1,810',
      100: '2,160',
      120: '2,710',
    },
    岐阜県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    静岡県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    愛知県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    三重県: {
      60: '1,370',
      80: '1,590',
      100: '1,940',
      120: '2,490',
    },
    滋賀県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    京都府: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    大阪府: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    兵庫県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    奈良県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    和歌山県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    鳥取県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    島根県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    岡山県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    広島県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    山口県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    徳島県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    香川県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    愛媛県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    高知県: {
      60: '1,260',
      80: '1,480',
      100: '1,830',
      120: '2,380',
    },
    福岡県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    佐賀県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    長崎県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    熊本県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    大分県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    宮崎県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    鹿児島県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
    沖縄県: {
      60: '1,480',
      80: '2,030',
      100: '2,710',
      120: '3,590',
    },
  },
  沖縄地方: {
    北海道: {
      60: '2,250',
      80: '2,800',
      100: '3,480',
      120: '4,360',
    },
    青森県: {
      60: '1,920',
      80: '2,470',
      100: '3,150',
      120: '4,030',
    },
    岩手県: {
      60: '1,920',
      80: '2,470',
      100: '3,150',
      120: '4,030',
    },
    宮城県: {
      60: '1,810',
      80: '2,360',
      100: '3,040',
      120: '3,920',
    },
    秋田県: {
      60: '1,920',
      80: '2,470',
      100: '3,150',
      120: '4,030',
    },
    山形県: {
      60: '1,810',
      80: '2,360',
      100: '3,040',
      120: '3,920',
    },
    福島県: {
      60: '1,810',
      80: '2,360',
      100: '3,040',
      120: '3,920',
    },
    茨城県: {
      60: '1,590',
      80: '2,140',
      100: '2,820',
      120: '3,700',
    },
    栃木県: {
      60: '1,590',
      80: '2,140',
      100: '2,820',
      120: '3,700',
    },
    群馬県: {
      60: '1,590',
      80: '2,140',
      100: '2,820',
      120: '3,700',
    },
    埼玉県: {
      60: '1,590',
      80: '2,140',
      100: '2,820',
      120: '3,700',
    },
    千葉県: {
      60: '1,590',
      80: '2,140',
      100: '2,820',
      120: '3,700',
    },
    東京都: {
      60: '1,590',
      80: '2,140',
      100: '2,820',
      120: '3,700',
    },
    神奈川県: {
      60: '1,590',
      80: '2,140',
      100: '2,820',
      120: '3,700',
    },
    新潟県: {
      60: '1,590',
      80: '2,140',
      100: '2,820',
      120: '3,700',
    },
    富山県: {
      60: '1,700',
      80: '2,250',
      100: '2,930',
      120: '3,810',
    },
    石川県: {
      60: '1,700',
      80: '2,250',
      100: '2,930',
      120: '3,810',
    },
    福井県: {
      60: '1,700',
      80: '2,250',
      100: '2,930',
      120: '3,810',
    },
    山梨県: {
      60: '1,590',
      80: '2,140',
      100: '2,820',
      120: '3,700',
    },
    長野県: {
      60: '1,700',
      80: '2,250',
      100: '2,930',
      120: '3,810',
    },
    岐阜県: {
      60: '1,590',
      80: '2,140',
      100: '2,820',
      120: '3,700',
    },
    静岡県: {
      60: '1,590',
      80: '2,140',
      100: '2,820',
      120: '3,700',
    },
    愛知県: {
      60: '1,590',
      80: '2,140',
      100: '2,820',
      120: '3,700',
    },
    三重県: {
      60: '1,590',
      80: '2,140',
      100: '2,820',
      120: '3,700',
    },
    滋賀県: {
      60: '1,590',
      80: '2,140',
      100: '2,820',
      120: '3,700',
    },
    京都府: {
      60: '1,590',
      80: '2,140',
      100: '2,820',
      120: '3,700',
    },
    大阪府: {
      60: '1,590',
      80: '2,140',
      100: '2,820',
      120: '3,700',
    },
    兵庫県: {
      60: '1,590',
      80: '2,140',
      100: '2,820',
      120: '3,700',
    },
    奈良県: {
      60: '1,590',
      80: '2,140',
      100: '2,820',
      120: '3,700',
    },
    和歌山県: {
      60: '1,590',
      80: '2,140',
      100: '2,820',
      120: '3,700',
    },
    鳥取県: {
      60: '1,590',
      80: '2,140',
      100: '2,820',
      120: '3,700',
    },
    島根県: {
      60: '1,590',
      80: '2,140',
      100: '2,820',
      120: '3,700',
    },
    岡山県: {
      60: '1,590',
      80: '2,140',
      100: '2,820',
      120: '3,700',
    },
    広島県: {
      60: '1,590',
      80: '2,140',
      100: '2,820',
      120: '3,700',
    },
    山口県: {
      60: '1,590',
      80: '2,140',
      100: '2,820',
      120: '3,700',
    },
    徳島県: {
      60: '1,590',
      80: '2,140',
      100: '2,820',
      120: '3,700',
    },
    香川県: {
      60: '1,590',
      80: '2,140',
      100: '2,820',
      120: '3,700',
    },
    愛媛県: {
      60: '1,590',
      80: '2,140',
      100: '2,820',
      120: '3,700',
    },
    高知県: {
      60: '1,590',
      80: '2,140',
      100: '2,820',
      120: '3,700',
    },
    福岡県: {
      60: '1,480',
      80: '2,030',
      100: '2,710',
      120: '3,590',
    },
    佐賀県: {
      60: '1,480',
      80: '2,030',
      100: '2,710',
      120: '3,590',
    },
    長崎県: {
      60: '1,480',
      80: '2,030',
      100: '2,710',
      120: '3,590',
    },
    熊本県: {
      60: '1,480',
      80: '2,030',
      100: '2,710',
      120: '3,590',
    },
    大分県: {
      60: '1,480',
      80: '2,030',
      100: '2,710',
      120: '3,590',
    },
    宮崎県: {
      60: '1,480',
      80: '2,030',
      100: '2,710',
      120: '3,590',
    },
    鹿児島県: {
      60: '1,480',
      80: '2,030',
      100: '2,710',
      120: '3,590',
    },
    沖縄県: {
      60: '1,150',
      80: '1,370',
      100: '1,720',
      120: '2,270',
    },
  },
};
