import React from 'react';
import { IsShow } from '../utils';
import { PRODUCT_ITEMS } from '../config';
import { Link } from 'react-router-dom';
import { paths } from '@/config';
import { replaceTofu } from '@/utils/string';
import {
  getDeliveryPrices,
  getLocaleStringLocales,
  isSeller,
  paymentMethod,
} from '@/utils';
import { GetValue } from '../utils/get-value';

const SaleInfo = (props: any) => {
  const items = props.sales.item;
  console.log('items: ', items);

  const users = props.users.mydata;

  const {
    sellerUserId,
    seller_user_id,
    sellerName,
    serller_name,
    postedAt,
    posted_at,
    fixPrice,
    fix_price,
    deliveryText,
    delivery_text,
    area,
    area_text,
    expenseText,
    expense_text,
    shippingExpense,
    shippingSize,
    shippingProvider,
    timingText,
    timing_text,
  } = items;

  const { id, prefecture } = users;

  let shippingProviderName = '';

  if (shippingProvider === 1) {
    shippingProviderName = '佐川急便';
  } else if (shippingProvider === 2) {
    shippingProviderName = 'ヤマト運輸';
  } else if (shippingProvider === 3) {
    shippingProviderName = '日本郵便 ';
  }

  return (
    <dl className="a__saleinfo_list">
      <dt>出品者</dt>
      <dd className="seller_name">
        {id === (sellerUserId ?? seller_user_id) ? (
          <Link to={paths.mypage.all}>
            {replaceTofu(sellerName ?? serller_name)}
          </Link>
        ) : (
          <Link to={`/users/${sellerUserId ?? seller_user_id}`}>
            {replaceTofu(sellerName ?? serller_name)}
          </Link>
        )}
      </dd>

      <dt>出品日時</dt>
      <dd>{postedAt ?? posted_at}</dd>

      {/* 項目 */}
      {Object.keys(PRODUCT_ITEMS).map((k, i) => {
        const d = PRODUCT_ITEMS[k];

        const nextKey = Object.keys(PRODUCT_ITEMS)[i + 1];
        const next = PRODUCT_ITEMS[nextKey];

        if (!IsShow(items, d) || !d.label) {
          return <React.Fragment key={k} />;
        }

        const v = d.type === 'bool' && items[k] ? d.checkboxLabel : undefined;

        if (next && !next.label) {
          console.log(`items[${nextKey}]: `, GetValue(nextKey, items));
        }

        return (
          <React.Fragment key={k}>
            {/* ラベル */}
            <dt>{d.label}</dt>
            {/* 値 */}
            <dd>
              {v ?? (
                <>
                  {(() => {
                    // 特定の値と同じになる場合
                    if (
                      d.linkValue &&
                      items[d.linkValue.name] === d.linkValue.value
                    ) {
                      return items[d.linkValue.target];
                    }

                    // 特定の値によってDisabledになる場合は表示しない
                    if (
                      d.linkDisabled &&
                      items[d.linkDisabled.name] === d.linkDisabled.value
                    ) {
                      return '-';
                    }

                    if (
                      typeof GetValue(`${k}`, items) === 'number' &&
                      GetValue(`${k}Text`, items)
                    ) {
                      return GetValue(`${k}Text`, items);
                    }

                    if (GetValue(k, items)) {
                      return `${GetValue(`${k}`, items)}${
                        d.unit ? d.unit : ''
                      }`;
                    }

                    return '-';
                  })()}
                  {next && !next.label && (
                    <>
                      <br />
                      {next.type === 'bool' && GetValue(nextKey, items)
                        ? next.checkboxLabel
                        : GetValue(nextKey, items)}
                    </>
                  )}
                </>
              )}
            </dd>
          </React.Fragment>
        );
      })}

      {/* その他 */}
      <dt>配送料の負担</dt>
      <dd>
        {expenseText ?? expense_text}
        {shippingProviderName ? (
          <>
            <br />
            {shippingProviderName}
          </>
        ) : null}
      </dd>

      <dt>配送方法</dt>
      <dd>{(delivery_text ?? deliveryText) || '-'}</dd>

      {window.isAuthed ? (
        <>
          {paymentMethod(expenseText) && !isSeller(sellerUserId, id) ? (
            <>
              <dt>
                クール便 <br />
                配送料目安
              </dt>
              {getDeliveryPrices(area, prefecture) ? (
                <dd>
                  約{getDeliveryPrices(area, prefecture)}円 <br />
                  ※条件によって異なる場合がございます
                </dd>
              ) : (
                <dd>
                  配送情報の取得に失敗したため、金額の表示に失敗しました。
                </dd>
              )}
            </>
          ) : null}
        </>
      ) : null}

      <dt>発送までの日数</dt>
      <dd>{timing_text ?? timingText}</dd>

      <dt>発送元の地域</dt>
      <dd>{area_text ?? area}</dd>

      <dt>即決価格</dt>
      <dd>
        {(fixPrice ?? fix_price) >= 2147483647 || (fixPrice ?? fix_price) === 0
          ? '-'
          : `¥${getLocaleStringLocales(fixPrice ?? fix_price)}`}
      </dd>
      <dt>配送サイズ区分</dt>
      <dd>{shippingExpense === 3 ? shippingSize : '-'}</dd>
    </dl>
  );
};

export default SaleInfo;
