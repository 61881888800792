import React, { useMemo } from 'react';
import { paths } from '@/config';

import CardLabel from './CardLabel';
import CommentLabel from './CommentLabel';
import CardBodyItem from './CardBodyItem';
import { getTimeType, closedMessage, shippingMessage } from './getTimeType';
import { replaceTofu } from '@/utils/string';

import './index.scss';

interface Props {
  id: number;
  imageUrl: string;
  fishKind: string;
  sellerName: string;
  sellerCompany: string;
  isCompany: boolean;
  fixPrice: string;
  displayPrice: string;
  bidCount: number;
  fixed: boolean;
  closeBidAtTemp: string;
  fishingSpot: string;
  weight: number;
  isNature?: boolean;
  shippingExpense: number;
  bid: boolean;
  closed: boolean;
  closedReason: number;
  commentAdd: boolean;
  imageAdd: boolean;
  size: string;
}

const Card: React.FC<Props> = ({
  id,
  imageUrl,
  fishKind,
  isCompany,
  sellerName,
  sellerCompany,
  fixPrice,
  displayPrice,
  bidCount,
  fixed,
  closeBidAtTemp,
  fishingSpot,
  weight,
  isNature,
  shippingExpense,
  bid,
  closed,
  closedReason,
  commentAdd,
  imageAdd,
  size,
}) => {
  const env = process.env.REACT_APP_ENV_CODE;
  const thumbnailImageUrl = imageUrl.replace(env, `${env}-thumbnail`);

  const { diff, type } = getTimeType(closeBidAtTemp, fixed);
  const { closeMessageLabel, closeMessageValue } = closedMessage(
    type,
    diff,
    closeBidAtTemp
  );
  const shippingMessageLabel = shippingMessage(shippingExpense);
  const prodValue = useMemo(() => {
    let message = '';

    if (!fishingSpot && !weight) return '未入力';
    if (fishingSpot) message += `${fishingSpot}産 `;
    if (weight) message += `${weight}g`;

    return message;
  }, []);

  return (
    <a
      href={`${paths.fishes.root}/${id}`}
      className="fish-card"
      target="_blank"
      rel="noopener noreferrer"
    >
      <CardLabel
        bid={bid}
        closed={closed}
        fixed={fixed}
        closedReason={closedReason}
      />

      <CommentLabel commentAdd={commentAdd} imageAdd={imageAdd} />

      <div className="fish-card__inner">
        <div className="fish-card__img">
          <img
            src={thumbnailImageUrl}
            onError={({ target }) => {
              // thumbnailの取得に失敗した時は、元イメージを取得する
              (target as HTMLImageElement).src = imageUrl;
            }}
            loading="lazy"
            decoding="async"
          />
        </div>

        <div className="fish-card__body">
          <h3 className="fish-card__title">{fishKind}</h3>

          <CardBodyItem
            label="出品者名"
            value={isCompany ? sellerCompany : replaceTofu(sellerName)}
            className="v-black seller-name"
          />
          {/* 現在価格 */}
          <CardBodyItem
            label="現在価格"
            value={`¥${displayPrice.toLocaleString()}`}
            className="v-red"
          />
          {/* 即決価格 */}
          {fixPrice === '-' ? (
            <CardBodyItem label="即決価格" value={'-'} className="v-black" />
          ) : (
            <CardBodyItem
              label="即決価格"
              value={`¥${fixPrice.toLocaleString()}`}
              className="v-black"
            />
          )}
          {/* 入札 */}
          <CardBodyItem label="入札" value={`${bidCount}`} />
          {/* 終了 */}
          <CardBodyItem label={closeMessageLabel} value={closeMessageValue} />
          {/* 産地・重量 */}
          <CardBodyItem label="産地・重量" value={prodValue} />
          {/* 天然・養殖 */}
          <CardBodyItem
            label={size}
            value={shippingMessageLabel}
            className="v-black l-black"
          />
        </div>
      </div>
    </a>
  );
};

export default Card;
