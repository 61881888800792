const applyStripeEnv = () => {
  const ndenv = process.env.REACT_APP_ENV_CODE;

  if (ndenv === 'local') {
    return {
      ApiKey: 'pk_test_GEjjSy1vUs3evFD4Cw3VwBeq',
      ConnectUrl:
        'https://connect.stripe.com/oauth/v2/authorize?response_type=code&client_id=ca_C8lCsgfrLcU8bMyjUSjiMyhisWkOiWyr&scope=read_write&redirect_uri=http://localhost:1110/stripe-callback&stripe_user[url]=&stripe_user[product_description]=採った野菜をその場で出品して、%0D%0Aほしい時にほしい人に届ける%0D%0A野菜専門セリアプリです&stripe_user[business_type]=sole_prop',
    };
  }

  if (ndenv === 'dev') {
    return {
      ApiKey: 'pk_test_GEjjSy1vUs3evFD4Cw3VwBeq',
      ConnectUrl:
        'https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_C8lCsgfrLcU8bMyjUSjiMyhisWkOiWyr&scope=read_write&redirect_uri=https://dev.vegetablesale.jp/stripe-callback&stripe_user[url]=&stripe_user[product_description]=採った野菜をその場で出品して、%0D%0Aほしい時にほしい人に届ける%0D%0A野菜専門セリアプリです&stripe_user[business_type]=sole_prop',
    };
  }

  if (ndenv === 'stg') {
    return {
      ApiKey: 'pk_test_GEjjSy1vUs3evFD4Cw3VwBeq',
      ConnectUrl:
        'https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_C8lCsgfrLcU8bMyjUSjiMyhisWkOiWyr&scope=read_write&redirect_uri=https://stg.vegetablesale.jp/stripe-callback&stripe_user[url]=&stripe_user[product_description]=採った野菜をその場で出品して、%0D%0Aほしい時にほしい人に届ける%0D%0A野菜専門セリアプリです&stripe_user[business_type]=sole_prop',
    };
  }

  if (ndenv === 'prd') {
    return {
      ApiKey: 'pk_live_iOL9PWvmILWJyFypmjFpaFur',
      ConnectUrl:
        'https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_C8lC1Qx0ksSF7kiLWka59pBrTLNS27kQ&scope=read_write&redirect_uri=https://vegetablesale.jp/stripe-callback&stripe_user[url]=&stripe_user[product_description]=採った野菜をその場で出品して、%0D%0Aほしい時にほしい人に届ける%0D%0A野菜専門セリアプリです&stripe_user[business_type]=sole_prop',
    };
  }

  return '';
};
const StripeConfig = applyStripeEnv();

function configureStripe() {
  return StripeConfig;
}

export default configureStripe;
