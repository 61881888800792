export const GetValue = (key: string, values: Record<string, any>) => {
  const normal = values[key];
  if (normal) return normal;

  const camel = values[toSnakeCase(key)];
  if (camel) return camel;

  const snake = values[toCamelCase(key)];
  if (snake) return snake;

  const addText = values[`${toCamelCase(key)}Text`];
  if (addText) return addText;

  return undefined;
};

function toSnakeCase(input: string): string {
  return input
    .replace(/([A-Z])/g, (match) => `_${match.toLowerCase()}`)
    .replace(/^_/, '');
}

function toCamelCase(input: string): string {
  return input.replace(/_([a-z])/g, (_, group) => group.toUpperCase());
}
