const shippingTimings = [
  {
    key: '1',
    name: 'shippingTimings1',
    value: 1,
    text: '落札当日に発送',
  },
  {
    key: '2',
    name: 'shippingTimings2',
    value: 2,
    text: '落札翌日に発送',
  },
  {
    key: '3',
    name: 'shippingTimings3',
    value: 3,
    text: '落札2日後に発送',
  },
  {
    key: '4',
    name: 'shippingTimings4',
    value: 4,
    text: '落札5日以内に発送',
  },
  {
    key: '5',
    name: 'shippingTimings5',
    value: 5,
    text: '落札10日以内に発送',
  },
];

export const shippingTimingNames = shippingTimings.map(({ name }) => name);

export default shippingTimings;
