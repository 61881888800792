export const CATEGORY = {
  FRUITS_AND_VEGETABLES: '果菜類',
  BEANS: '豆類',
  LEAFY_STEM_VEGETABLES: '葉茎菜類',
  WESTERN_VEGETABLES: '洋菜類',
  ROOT_VEGETABLES: '根菜類',
  WEAK: '軟弱類',
  MUSHROOMS: '菌茸類',
  // FRUITS: '果実類',
} as const;

interface VegetableType {
  name: string;
  category: string;
  grade: string[];
  rank: string[] | { [x: string]: string[] };
}

// 果菜類
const FRUITS_AND_VEGETABLES: VegetableType[] = [
  {
    category: CATEGORY['FRUITS_AND_VEGETABLES'],
    name: 'きゅうり',
    grade: ['A', 'B', 'C'],
    rank: ['2L', 'L', 'M', 'S'],
  },
  {
    category: CATEGORY['FRUITS_AND_VEGETABLES'],
    name: 'トマト',
    grade: ['A', 'B', 'C'],
    rank: ['3L', '2L', 'L', 'M', 'S'],
  },
  {
    category: CATEGORY['FRUITS_AND_VEGETABLES'],
    name: 'ミニトマト',
    grade: ['A', 'B'],
    rank: ['2L', 'L', 'M', 'S'],
  },
  {
    category: CATEGORY['FRUITS_AND_VEGETABLES'],
    name: 'なす',
    grade: ['A', 'B', 'C'],
    rank: ['2L', 'L', 'M', 'S'],
  },
  {
    category: CATEGORY['FRUITS_AND_VEGETABLES'],
    name: '大長なす',
    grade: ['A', 'B', 'C'],
    rank: {
      A: ['3L', '2L', 'L'],
      B: ['3L', '2L', 'L'],
      C: ['L', 'M'],
    },
  },
  {
    category: CATEGORY['FRUITS_AND_VEGETABLES'],
    name: 'かぼちゃ（中型種）',
    grade: ['A', 'B'],
    rank: [],
  },
  {
    category: CATEGORY['FRUITS_AND_VEGETABLES'],
    name: 'かぼちゃ（小型種）',
    grade: ['A', 'B', 'C'],
    rank: ['3L', '2L', 'L', 'M', 'S', '2S', '3S'],
  },
  {
    category: CATEGORY['FRUITS_AND_VEGETABLES'],
    name: 'ピーマン',
    grade: ['A', 'B'],
    rank: {
      A: ['2L', 'L', 'M', 'S'],
      B: ['L', 'M'],
    },
  },
  {
    category: CATEGORY['FRUITS_AND_VEGETABLES'],
    name: 'ししとう',
    grade: ['A', 'B'],
    rank: ['L', 'M'],
  },
  {
    category: CATEGORY['FRUITS_AND_VEGETABLES'],
    name: 'スイートコーン',
    grade: ['A', 'B'],
    rank: ['4L', '3L', '2L', 'L', 'M', 'S'],
  },
  {
    category: CATEGORY['FRUITS_AND_VEGETABLES'],
    name: 'おくら',
    grade: ['A', 'B'],
    rank: ['L', 'M'],
  },
];

// 豆類
const BEANS: VegetableType[] = [
  {
    category: CATEGORY['BEANS'],
    name: 'きぬさやえんどう',
    grade: ['A', 'B', 'C'],
    rank: {
      A: ['M', 'S'],
      B: ['M'],
      C: ['M'],
    },
  },
  {
    category: CATEGORY['BEANS'],
    name: 'オランダえんどう',
    grade: ['A', 'B'],
    rank: {
      A: ['L', 'M'],
      B: [],
    },
  },
  {
    category: CATEGORY['BEANS'],
    name: 'スナップえんどう',
    grade: ['A', 'B', 'C'],
    rank: ['M'],
  },
  {
    category: CATEGORY['BEANS'],
    name: '実えんどう',
    grade: ['A', 'B'],
    rank: ['L', 'M', 'S'],
  },
  {
    category: CATEGORY['BEANS'],
    name: 'さやいんげん',
    grade: ['A', 'B'],
    rank: ['L', 'M', 'S'],
  },
  {
    category: CATEGORY['BEANS'],
    name: 'そらまめ',
    grade: ['A', 'B'],
    rank: {
      A: ['L', 'M'],
      B: [],
    },
  },
  {
    category: CATEGORY['BEANS'],
    name: 'えだまめ',
    grade: ['A', 'B'],
    rank: [],
  },
];

// 葉茎菜類
const LEAFY_STEM_VEGETABLES: VegetableType[] = [
  {
    category: CATEGORY['LEAFY_STEM_VEGETABLES'],
    name: 'キャベツ',
    grade: ['A'],
    rank: ['3L', '2L', 'L', 'M', 'S'],
  },
  {
    category: CATEGORY['LEAFY_STEM_VEGETABLES'],
    name: 'はくさい',
    grade: ['A', 'B'],
    rank: ['3L', '2L', 'L', 'M', 'S'],
  },
  {
    category: CATEGORY['LEAFY_STEM_VEGETABLES'],
    name: 'ほうれんそう',
    grade: ['A', 'B'],
    rank: ['3L', '2L', 'L', 'M'],
  },
  {
    category: CATEGORY['LEAFY_STEM_VEGETABLES'],
    name: '青ねぎ',
    grade: ['A', 'B'],
    rank: ['2L', 'L', 'M', 'S'],
  },
  {
    category: CATEGORY['LEAFY_STEM_VEGETABLES'],
    name: '白ねぎ',
    grade: ['A', 'B'],
    rank: ['3L', '2L', 'L', 'M', 'S'],
  },
  {
    category: CATEGORY['LEAFY_STEM_VEGETABLES'],
    name: 'わけぎ',
    grade: ['A', 'B'],
    rank: ['2L', 'L', 'M'],
  },
];

// 洋菜類
const WESTERN_VEGETABLES: VegetableType[] = [
  {
    category: CATEGORY['WESTERN_VEGETABLES'],
    name: 'レタス',
    grade: ['A', 'B'],
    rank: ['2L', 'L', 'M', 'S', '2S'],
  },
  {
    category: CATEGORY['WESTERN_VEGETABLES'],
    name: 'リーフレタス（非結球）',
    grade: ['A'],
    rank: ['2L', 'L', 'M'],
  },
  {
    category: CATEGORY['WESTERN_VEGETABLES'],
    name: 'カリフラワー',
    grade: ['A'],
    rank: ['3L', '2L', 'L', 'M', 'S'],
  },
  {
    category: CATEGORY['WESTERN_VEGETABLES'],
    name: 'ブロッコリー',
    grade: ['A', 'B'],
    rank: ['3L', '2L', 'L', 'M', 'S', '側'],
  },
  {
    category: CATEGORY['WESTERN_VEGETABLES'],
    name: 'アスパラガス',
    grade: ['A', 'B'],
    rank: ['3L', '2L', 'L', 'M', 'S'],
  },
];

// 根菜類
const ROOT_VEGETABLES: VegetableType[] = [
  {
    category: CATEGORY['ROOT_VEGETABLES'],
    name: 'だいこん（10月〜6月 秋冬・春だいこん）',
    grade: ['A', 'B'],
    rank: ['4L', '3L', '2L', 'L', 'M', 'S'],
  },
  {
    category: CATEGORY['ROOT_VEGETABLES'],
    name: 'だいこん（7月〜9月 夏だいこん）',
    grade: ['A', 'B'],
    rank: ['3L', '2L', 'L', 'M'],
  },
  {
    category: CATEGORY['ROOT_VEGETABLES'],
    name: 'だいこん（年間 丸だいこん）',
    grade: ['A', 'B'],
    rank: ['L', 'M', 'S'],
  },
  {
    category: CATEGORY['ROOT_VEGETABLES'],
    name: 'にんじん',
    grade: ['A', 'B'],
    rank: ['3L', '2L', 'L', 'M', 'S'],
  },
  {
    category: CATEGORY['ROOT_VEGETABLES'],
    name: 'たまねぎ',
    grade: ['A', 'B'],
    rank: ['2L', 'L', 'M', 'S', '2S'],
  },
  {
    category: CATEGORY['ROOT_VEGETABLES'],
    name: 'ばれいしょ',
    grade: ['A', 'B', 'C'],
    rank: ['3L', '2L', 'L', 'M', 'S', '2S'],
  },
  {
    category: CATEGORY['ROOT_VEGETABLES'],
    name: 'さといも',
    grade: ['A'],
    rank: ['3L', '2L', 'L', 'M', 'S', '2S'],
  },
  {
    category: CATEGORY['ROOT_VEGETABLES'],
    name: 'かんしょ',
    grade: ['A', 'B'],
    rank: ['3L', '2L', 'L', 'M', 'S', '2S'],
  },
  {
    category: CATEGORY['ROOT_VEGETABLES'],
    name: 'やまのいも',
    grade: ['A', 'B'],
    rank: ['3L', '2L', 'L', 'M', 'S'],
  },
  {
    category: CATEGORY['ROOT_VEGETABLES'],
    name: 'ながいも',
    grade: ['A', 'B'],
    rank: ['3L', '2L', 'L', 'M', 'S'],
  },
  {
    category: CATEGORY['ROOT_VEGETABLES'],
    name: 'れんこん',
    grade: ['A'],
    rank: ['3L', '2L', 'L', 'M', 'S'],
  },
  {
    category: CATEGORY['ROOT_VEGETABLES'],
    name: 'かぶ',
    grade: ['A', 'B'],
    rank: ['2L', 'L', 'M', 'S'],
  },
  {
    category: CATEGORY['ROOT_VEGETABLES'],
    name: 'しょうが',
    grade: ['A', 'B'],
    rank: ['2L', 'L', 'M', 'S'],
  },
  {
    category: CATEGORY['ROOT_VEGETABLES'],
    name: 'らっきょう',
    grade: ['A', 'B'],
    rank: ['L', 'M', 'S', '2S'],
  },
  {
    category: CATEGORY['ROOT_VEGETABLES'],
    name: 'にんにく',
    grade: ['A', 'B'],
    rank: ['2L', 'L', 'M', 'S'],
  },
];

// 軟弱類
const WEAK: VegetableType[] = [
  {
    category: CATEGORY['WEAK'],
    name: 'こまつな',
    grade: ['A', 'B'],
    rank: ['L', 'M'],
  },
  {
    category: CATEGORY['WEAK'],
    name: 'しゅんぎく',
    grade: ['A', 'B'],
    rank: ['L', 'M'],
  },
  {
    category: CATEGORY['WEAK'],
    name: 'ちんげんさい（箱詰定数・袋）',
    grade: ['A', 'B'],
    rank: ['L', 'M', 'S'],
  },
  {
    category: CATEGORY['WEAK'],
    name: 'ちんげんさい（箱詰定数・株）',
    grade: ['A', 'B'],
    rank: ['L', 'M'],
  },
  {
    category: CATEGORY['WEAK'],
    name: 'にら',
    grade: ['A', 'B'],
    rank: ['L', 'M', 'S'],
  },
  {
    category: CATEGORY['WEAK'],
    name: 'みょうが',
    grade: ['A'],
    rank: ['2L', 'L', 'M', 'S'],
  },
  {
    category: CATEGORY['WEAK'],
    name: 'みつば',
    grade: ['A'],
    rank: ['L', 'M', 'S'],
  },
  {
    category: CATEGORY['WEAK'],
    name: 'ふき',
    grade: ['A', 'B'],
    rank: ['3L', '2L', 'L', 'M', 'S', '2S'],
  },
  {
    category: CATEGORY['WEAK'],
    name: 'みずな',
    grade: ['A', 'B'],
    rank: ['2L', 'L', 'M'],
  },
];

// 菌茸類
const MUSHROOMS: VegetableType[] = [
  {
    category: CATEGORY['MUSHROOMS'],
    name: 'なめこ',
    grade: ['A', 'B'],
    rank: ['L', 'M', 'S', '2S'],
  },
  {
    category: CATEGORY['MUSHROOMS'],
    name: '生しいたけ',
    grade: [],
    rank: ['特2L', '特L', '特M', '特S', '特A', '2L', 'L', 'M', 'S', 'A'],
  },
  {
    category: CATEGORY['MUSHROOMS'],
    name: 'ひらたけ',
    grade: ['A', 'B'],
    rank: ['L', 'M', 'S'],
  },
  {
    category: CATEGORY['MUSHROOMS'],
    name: 'えのきだけ',
    grade: ['A', 'B', 'C'],
    rank: [],
  },
  {
    category: CATEGORY['MUSHROOMS'],
    name: 'まつたけ',
    grade: ['特大', '特選', 'つぼみ', '中つぼみ', 'ころ', 'ひらき'],
    rank: [],
  },
  {
    category: CATEGORY['MUSHROOMS'],
    name: 'ぶなしめじ',
    grade: ['A', 'B'],
    rank: ['L', 'M'],
  },
];

// 果実類
const FRUITS: VegetableType[] = [
  // 23-06-01 11:04
  // メロン、すいか、いちごはセリ場では正確には野菜として扱われていますが、ユーザーとしては分かりにくいので、Fruitにカテゴライズしたいと思います。
  // https://www.chatwork.com/#!rid225943364-1715937788299313152
  // {
  //   category: CATEGORY['FRUITS'],
  //   name: 'いちご',
  //   grade: ['A', 'B'],
  //   rank: {
  //     A: ['3L', '2L', 'L', 'M', 'S', '2S'],
  //     B: ['L', 'M', 'S'],
  //   },
  // },
  // {
  //   category: CATEGORY['FRUITS'],
  //   name: 'すいか',
  //   grade: ['A', 'B', 'C'],
  //   rank: ['4L', '3L', '2L', 'L', 'M', 'S'],
  // },
];

export const VEGETABLE_DB: VegetableType[] = [
  ...FRUITS_AND_VEGETABLES,
  ...BEANS,
  ...BEANS,
  ...LEAFY_STEM_VEGETABLES,
  ...WESTERN_VEGETABLES,
  ...ROOT_VEGETABLES,
  ...WEAK,
  ...MUSHROOMS,
  ...FRUITS,
];
