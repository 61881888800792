import * as React from 'react';
import { Image } from 'semantic-ui-react';
import classNames from 'classnames';
import qs from 'qs';
import { constants } from '../../../../constants';

function TwitterShareButton(props) {
  const isDesktop = props.type === 'desktop';
  const isMobile = props.type === 'mobile';
  return (
    <a
      href={
        `${constants.SNS_SHARE_URL.twitter}?` +
        qs.stringify({
          text: props.explanation,
          url: window.location.href,
        })
      }
      target="_blank"
      rel="noopener noreferrer"
      className={classNames({
        grid_twitter_container: isDesktop,
      })}
    >
      <Image
        src="https://vegetablesale.jp/assets/fs_fish_icon_twitter.svg"
        className={classNames({
          view_icon_twitter: isDesktop,
          main_icon_twitter: isMobile,
        })}
      />
    </a>
  );
}

export default TwitterShareButton;
