import deliveryMethods from '../config/app/deliveryMethod';
import shippingExpenses from '../config/app/shippingExpense';
import shippingTimings from '../config/app/shippingTiming';
import savingMethods from '../config/app/savingMethod';
import ikejimeTimings from '../config/app/ikejimeTiming';
import ikejimeMethods from '../config/app/ikejimeMethod';
import cleaningMethods from '../config/app/cleaningMethod';
// 追加
import grade from '../config/app/grade';
import cultivationMethod from '../config/app/cultivationMethod';
import saveMethod from '../config/app/saveMethod';
import pesticides from '../config/app/pesticides';
import genetic from '../config/app/genetic';
import soil from '../config/app/soil';
import jas from '../config/app/jas';

import * as queryParams from './queryParams';

// TODO検索
export const searchStatus = (status) => {
  switch (status) {
    case 'all':
      return {};
    case 'canBidding':
      return {
        fixed: 0,
        closed: 0,
      };
    case 'onDisplay':
      return {
        bid: 0,
        fixed: 0,
        closed: 0,
      };
    case 'bidding':
      return {
        bid: 1,
        fixed: 0,
        closed: 0,
      };
    case 'alreadyCompletedBidding':
      return {
        fixed: 1,
      };
    case 'biddingComplete':
      return {
        closed: 2,
      };
    default:
      return {};
  }
};

export const unzipURL = (str, defaultSort) => {
  let params = {};

  const queryParam = queryParams.parse(str);

  params.page = queryParam.page || '1';
  params.sort = queryParam.sort || defaultSort;
  params.status = queryParam.status || 'all';
  params.area = queryParam.area || '0';

  if (queryParam.ikejime_method) {
    params.ikejime_method = queryParam.ikejime_method;
  }

  if (queryParam.cleaning_method) {
    params.cleaning_method = queryParam.cleaning_method;
  }

  if (queryParam.delivery_method) {
    params.delivery_method = queryParam.delivery_method;
  }

  if (queryParam.ikejime_timing) {
    params.ikejime_timing = queryParam.ikejime_timing;
  }

  if (queryParam.saving_method) {
    params.saving_method = queryParam.saving_method;
  }

  if (queryParam.shipping_expense) {
    params.shipping_expense = queryParam.shipping_expense;
  }

  if (queryParam.shipping_timing) {
    params.shipping_timing = queryParam.shipping_timing;
  }

  if (queryParam.keyword) {
    params.keyword = queryParam.keyword;
  }

  if (queryParam.max_price) {
    params.max_price = queryParam.max_price;
  }

  if (queryParam.min_price) {
    params.min_price = queryParam.min_price;
  }

  if (queryParam.max_size) {
    params.max_size = queryParam.max_size;
  }

  if (queryParam.min_size) {
    params.min_size = queryParam.min_size;
  }

  if (queryParam.max_weight) {
    params.max_weight = queryParam.max_weight;
  }

  if (queryParam.min_weight) {
    params.min_weight = queryParam.min_weight;
  }

  if (queryParam.max_fix_price) {
    params.max_fix_price = queryParam.max_fix_price;
  }

  if (queryParam.min_fix_price) {
    params.min_fix_price = queryParam.min_fix_price;
  }

  if (queryParam.is_new) {
    params.is_new = queryParam.is_new;
  }

  if (queryParam.is_immediately) {
    params.is_immediately = queryParam.is_immediately;
  }

  if (queryParam.is_nature) {
    params.is_nature = queryParam.is_nature;
  }

  // 追加
  if (queryParam.grade) {
    params.grade = queryParam.grade;
  }

  if (queryParam.cultivation_method) {
    params.cultivation_method = queryParam.cultivation_method;
  }

  if (queryParam.soil) {
    params.soil = queryParam.soil;
  }

  if (queryParam.pesticides) {
    params.pesticides = queryParam.pesticides;
  }

  if (queryParam.save_method_seller) {
    params.save_method_seller = queryParam.save_method_seller;
  }

  if (queryParam.save_method_user) {
    params.save_method_user = queryParam.save_method_user;
  }

  if (queryParam.genetic) {
    params.genetic = queryParam.genetic;
  }

  if (queryParam.jas) {
    params.jas = queryParam.jas;
  }

  // ベジタブル
  // カテゴリー
  if (queryParam.category) {
    // params.category = encodeURI(queryParam.category);
    params.category = queryParam.category;
  }

  // 品種名
  if (queryParam.breed) {
    // params.breed = encodeURI(queryParam.breed);
    params.breed = queryParam.breed;
  }

  params = Object.assign(params, searchStatus(params.status));

  return { params };
};

/**
 * サイドメニューの検索結果がのこるようにする
 */
export const setSearchResult = (location, change) => {
  const queryParam = queryParams.parse(location.search);

  // 検索結果を反映
  change('keyword', queryParam.keyword || '');
  change('status', queryParam.status || '');
  change('area', queryParam.area || '');

  if (queryParam.sort) {
    const sort = queryParam.sort.replaceAll(' ', '+');
    change('sort', sort || '');
  }

  // サイズ
  change('min_size', queryParam.min_size || '');
  change('max_size', queryParam.max_size || '');
  // 重量
  change('min_weight', queryParam.min_weight || '');
  change('max_weight', queryParam.max_weight || '');

  // 価格
  change('min_price', queryParam.min_price || '');
  change('max_price', queryParam.max_price || '');

  // 即決価格
  change('min_fix_price', queryParam.min_fix_price || '');
  change('max_fix_price', queryParam.max_fix_price || '');

  // 締め方
  if (queryParam.ikejime_method) {
    const ikejimeMethod = ikejimeMethods.map((ik) => {
      return {
        ...ik,
        value: String(ik.value),
      };
    });

    queryParam.ikejime_method.forEach((ik) => {
      const found = ikejimeMethod.find(({ value }) => {
        return value === ik;
      });

      if (found) {
        change(found.name, true);
      }
    });

    // アコーディオンを開いた状態にする
    const pcAccordion = document.getElementById('ikejime_method');
    if (pcAccordion) {
      pcAccordion.checked = true;
    }
  }

  // 処理方法
  if (queryParam.cleaning_method) {
    const cleaningMethod = cleaningMethods.map((ik) => {
      return {
        ...ik,
        value: String(ik.value),
      };
    });

    queryParam.cleaning_method.forEach((ik) => {
      const found = cleaningMethod.find(({ value }) => {
        return value === ik;
      });

      if (found) {
        change(found.name, true);
      }
    });

    // アコーディオンを開いた状態にする
    const pcAccordion = document.getElementById('cleaning_method');
    if (pcAccordion) {
      pcAccordion.checked = true;
    }
  }

  // 締め処理を行なったタイミング
  if (queryParam.ikejime_timing) {
    const found = ikejimeTimings.find(({ value }) => {
      return value === Number(queryParam.ikejime_timing[0]);
    });

    if (found) {
      change('ikejime_timing', found.text);
    }

    // アコーディオンを開いた状態にする
    const pcAccordion = document.getElementById('ikejime_timing');
    if (pcAccordion) {
      pcAccordion.checked = true;
    }
  }

  // 分類
  if (queryParam.is_nature) {
    change('is_nature', true);
  }
  if (queryParam.is_new) {
    change('is_new', true);
  }
  if (queryParam.is_immediately) {
    change('is_immediately', true);
  }

  if (queryParam.is_nature || queryParam.is_new || queryParam.is_immediately) {
    // アコーディオンを開いた状態にする
    const pcAccordion = document.getElementById('catalog');
    if (pcAccordion) {
      pcAccordion.checked = true;
    }
  }

  // 保管方法
  if (queryParam.saving_method) {
    const savingMethod = savingMethods.map((ik) => {
      return {
        ...ik,
        value: String(ik.value),
      };
    });

    queryParam.saving_method.forEach((ik) => {
      const found = savingMethod.find(({ value }) => {
        return value === ik;
      });

      if (found) {
        change(found.name, true);
      }
    });

    // アコーディオンを開いた状態にする
    const pcAccordion = document.getElementById('saving_method');
    if (pcAccordion) {
      pcAccordion.checked = true;
    }
  }

  // 配送料の負担
  if (queryParam.shipping_expense) {
    const shippingExpense = shippingExpenses.map((ik) => {
      return {
        ...ik,
        value: String(ik.value),
      };
    });

    queryParam.shipping_expense.forEach((ik) => {
      const found = shippingExpense.find(({ value }) => {
        return value === ik;
      });

      if (found) {
        change(found.name, true);
      }
    });

    // アコーディオンを開いた状態にする
    const pcAccordion = document.getElementById('shipping_expense');
    if (pcAccordion) {
      pcAccordion.checked = true;
    }
  }

  // 配送方法
  if (queryParam.delivery_method) {
    const deliveryMethod = deliveryMethods.map((ik) => {
      return {
        ...ik,
        value: String(ik.value),
      };
    });

    queryParam.delivery_method.forEach((ik) => {
      const found = deliveryMethod.find(({ value }) => {
        return value === ik;
      });

      if (found) {
        change(found.name, true);
      }
    });

    // アコーディオンを開いた状態にする
    const pcAccordion = document.getElementById('delivery_method');
    if (pcAccordion) {
      pcAccordion.checked = true;
    }
  }

  // 配送日数
  if (queryParam.shipping_timing) {
    const shippingTiming = shippingTimings.map((ik) => {
      return {
        ...ik,
        value: String(ik.value),
      };
    });

    queryParam.shipping_timing.forEach((ik) => {
      const found = shippingTiming.find(({ value }) => {
        return value === ik;
      });

      if (found) {
        change(found.name, true);
      }
    });

    // アコーディオンを開いた状態にする
    const pcAccordion = document.getElementById('shipping_timing');
    if (pcAccordion) {
      pcAccordion.checked = true;
    }
  }

  // 追加
  // 等級
  if (queryParam.grade) {
    const values = grade.map((ik) => {
      return {
        ...ik,
        value: String(ik.value),
      };
    });

    queryParam.grade.forEach((ik) => {
      const found = values.find(({ value }) => {
        return value === ik;
      });

      if (found) {
        change(found.name, true);
      }
    });

    // アコーディオンを開いた状態にする
    const pcAccordion = document.getElementById('grade');
    if (pcAccordion) {
      pcAccordion.checked = true;
    }
  }

  // 栽培方法
  if (queryParam.cultivation_method) {
    const values = cultivationMethod.map((ik) => {
      return {
        ...ik,
        value: String(ik.value),
      };
    });

    queryParam.cultivation_method.forEach((ik) => {
      const found = values.find(({ value }) => {
        return value === ik;
      });

      if (found) {
        change(found.name, true);
      }
    });

    // アコーディオンを開いた状態にする
    const pcAccordion = document.getElementById('cultivation_method');
    if (pcAccordion) {
      pcAccordion.checked = true;
    }
  }

  // 土壌
  if (queryParam.soil) {
    const values = soil.map((ik) => {
      return {
        ...ik,
        value: String(ik.value),
      };
    });

    queryParam.soil.forEach((ik) => {
      const found = values.find(({ value }) => {
        return value === ik;
      });

      if (found) {
        change(found.name, true);
      }
    });

    // アコーディオンを開いた状態にする
    const pcAccordion = document.getElementById('soil');
    if (pcAccordion) {
      pcAccordion.checked = true;
    }
  }

  // 農薬
  if (queryParam.pesticides) {
    const values = pesticides.map((ik) => {
      return {
        ...ik,
        value: String(ik.value),
      };
    });

    queryParam.pesticides.forEach((ik) => {
      const found = values.find(({ value }) => {
        return value === ik;
      });

      if (found) {
        change(found.name, true);
      }
    });

    // アコーディオンを開いた状態にする
    const pcAccordion = document.getElementById('pesticides');
    if (pcAccordion) {
      pcAccordion.checked = true;
    }
  }

  if (queryParam.save_method_seller) {
    const values = saveMethod.map((ik) => {
      return {
        ...ik,
        value: String(ik.value),
      };
    });

    queryParam.save_method_seller.forEach((ik) => {
      const found = values.find(({ value }) => {
        return value === ik;
      });

      if (found) {
        change(found.name, true);
      }
    });

    // アコーディオンを開いた状態にする
    const pcAccordion = document.getElementById('save_method_seller');
    if (pcAccordion) {
      pcAccordion.checked = true;
    }
  }

  if (queryParam.save_method_user) {
    const values = saveMethod.map((ik) => {
      return {
        ...ik,
        value: String(ik.value),
      };
    });

    queryParam.save_method_user.forEach((ik) => {
      const found = values.find(({ value }) => {
        return value === ik;
      });

      if (found) {
        change(found.name, true);
      }
    });

    // アコーディオンを開いた状態にする
    const pcAccordion = document.getElementById('save_method_user');
    if (pcAccordion) {
      pcAccordion.checked = true;
    }
  }

  if (queryParam.genetic) {
    const values = genetic.map((ik) => {
      return {
        ...ik,
        value: String(ik.value),
      };
    });

    queryParam.genetic.forEach((ik) => {
      const found = values.find(({ value }) => {
        return value === ik;
      });

      if (found) {
        change(found.name, true);
      }
    });

    // アコーディオンを開いた状態にする
    const pcAccordion = document.getElementById('genetic');
    if (pcAccordion) {
      pcAccordion.checked = true;
    }
  }

  if (queryParam.jas) {
    const values = jas.map((ik) => {
      return {
        ...ik,
        value: String(ik.value),
      };
    });

    queryParam.jas.forEach((ik) => {
      const found = values.find(({ value }) => {
        return value === ik;
      });

      if (found) {
        change(found.name, true);
      }
    });

    // アコーディオンを開いた状態にする
    const pcAccordion = document.getElementById('jas');
    if (pcAccordion) {
      pcAccordion.checked = true;
    }
  }

  // ベジタブル
  // カテゴリー
  if (queryParam.category) {
    change('category', queryParam.category || '');
  }
  // 品種名
  if (queryParam.breed) {
    change('breed', queryParam.breed || '');
  }
};
