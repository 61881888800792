/**
 *  SNSのサムネ画像を初期画像に設定する
 *  FacebookとGoogleログインにのみ対応
 * @param {String} picture CognitoUserPoolのjwtのpicture
 * @return {String} サムネイルのURL
 */
export default function findImageUrl(picture) {
  if (picture === '') {
    return 'https://vegetablesale.jp/assets/noimage_normal.png';
  }
  if (picture.startsWith('http')) {
    // Googleログインのとき
    return picture;
  }
  try {
    // Facebookログインのとき
    return JSON.parse(picture).data.url;
  } catch (e) {
    return 'https://vegetablesale.jp/assets/noimage_normal.png';
  }
}
