import * as React from 'react';
import Card from '@/components/Common/Card';
import { ListItem } from '@/components/Common';
import enhance from './enhancer';
import { availableFishCards } from './utils';
import { useDetailList } from '@/hooks';
import { useRecoilValue } from 'recoil';
import { listViewStore } from '@/store';

function SalesList(props) {
  const listView = useRecoilValue(listViewStore);

  const { data } = props;

  const INVALID_VALUE = 2147483647;

  // 追記（詳細情報取得）
  const { detailsData } = useDetailList(data.map((e) => e.id));

  const isSwitch = (() => {
    const path = window.location.pathname;
    if (path.includes('/users')) {
      return true;
    }

    if (path.includes('/mypage')) {
      return true;
    }

    return false;
  })();

  return (
    <div id="observable_fishes_view" className="app_sales_list flex">
      {availableFishCards(data) && (listView === 0 || isSwitch)
        ? data.map((item) => {
            const detail = detailsData.find((f) => f.saleId === item.id);
            if (!detail) return;
            return (
              <Card
                key={item.id}
                id={item.id}
                fishKind={item.name || ''}
                imageUrl={item.imageUrl || item.imageURL}
                sellerName={item.sellerName}
                sellerCompany={item.sellerCompany}
                fixPrice={INVALID_VALUE === item.fixPrice ? '-' : item.fixPrice}
                isCompany={item.isCompany}
                displayPrice={item.displayPrice}
                bidCount={item.bidCount}
                fixed={item.fixed}
                closeBidAtTemp={item.closeBidAtTemp}
                fishingSpot={detail.originText}
                weight={item.weight}
                isNature={item.isNature}
                shippingExpense={item.shippingExpense}
                bid={item.bid}
                closed={item.closed}
                closedReason={item.closedReason}
                commentAdd={item.commentAdd}
                imageAdd={item.imageAdd}
                size={detail.sizeText}
              />
            );
          })
        : data.map((item) => {
            const detail = detailsData.find((f) => f.saleId === item.id);
            if (!detail) return;
            return (
              <ListItem
                key={item.id}
                data={{
                  ...item,
                  fishKind: item.name || '',
                  size: detail.sizeText,
                  fishingSpot: detail.originText,
                  weight: detail.weight,
                  breed: detail.breed,
                  fixPrice:
                    INVALID_VALUE === item.fixPrice ? '-' : item.fixPrice,
                }}
              />
            );
          })}
    </div>
  );
}

export default enhance((props) => <SalesList {...props} />);
