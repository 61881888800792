import React from 'react';
import { Input, Button, Icon } from 'semantic-ui-react';

const SearchInput = (props) => {
  const { submit } = props;

  return (
    <div className="b__sidenav__search-input">
      <Input
        placeholder="トマトやなすなどで検索"
        action
        type="search"
        onChange={(event, { value }) => {
          props.input.onChange(value);
        }}
      >
        <input />
        <Button
          icon
          positive
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            document.activeElement?.blur();
            submit();
          }}
        >
          検索
          <Icon name="search" />
        </Button>
      </Input>
    </div>
  );
};

export default SearchInput;
