export const ACTIVITY_CODES = {
  USER_SIGNUP: 'user_signup',
  SELLER_BIDDED: 'seller_bidded',
  SELLER_SALE_FIXED: 'seller_sale_fixed',
  SELLER_RECIEVED_MESSAGE: 'seller_recieved_message',
  SELLER_SALE_EXPIRED: 'seller_sale_expired',
  ANOTHER_USER_BIDDED: 'another_user_bidded',
  USER_FIXED_SALE: 'user_fixed_sale',
  USER_RECIEVED_MESSAGE: 'user_recieved_message',
  ANOTHER_USER_FIXED_SALE: 'another_user_fixed_sale',
  ADMIN_CLOSED_SALE: 'admin_closed_sale',
  SELLER_WITHDRAW_SALE: 'seller_withdraw_sale',
  PAYMENT_FAILURE: 'payment_failure',
  SELLER_SEND_MESSAGE: 'seller_send_message',
  FOLLOW_USER_SELL: 'follow_user_sell',
  REVIEW_RECEIVED: 'review_received',
  REVIEW_REPLY_COMMENT_RECEIVED: 'review_reply_comment_received',
};
