import { PAYMENT_TYPES } from './paymentTypes';
import { DELIVERY_SIZES } from './deliverySizes';
import { SNS_SHARE_URL } from './snsShareUrl';
import { IMAGES } from './images';
import { PAGINATION } from './pagination';
import { ACTIVITY_TEMPLATES } from './activityTemplates';
import { ACTIVITY_CODES } from './activityCodes';
import { URL } from './url';
import { PRICES } from './prices';
import { SIZES } from './sizes';
import { FISHING_YEAR } from './fishingYear';
import { TIMES, SECOND_MILLISECOND, MINUTE_MILLISECOND } from './timeType';
import * as ERROR_CASE from './errorCase';
import * as MYPAGE from './mypage';
import * as CLOSED_REASON from './closedReason';

export const constants = {
  PAYMENT_TYPES,
  DELIVERY_SIZES,
  SNS_SHARE_URL,
  IMAGES,
  PAGINATION,
  ACTIVITY_TEMPLATES,
  ACTIVITY_CODES,
  URL,
  PRICES,
  SIZES,
  FISHING_YEAR,
  TIMES,
  SECOND_MILLISECOND,
  MINUTE_MILLISECOND,
};

export { ERROR_CASE, MYPAGE, CLOSED_REASON };
