const saveMethod = [
  {
    key: '1',
    value: 1,
    name: 'save_method1',
    text: '常温',
  },
  {
    key: '2',
    value: 2,
    name: 'save_method2',
    text: '保温庫',
  },
  {
    key: '3',
    value: 3,
    name: 'save_method3',
    text: '冷蔵庫',
  },
  {
    key: '4',
    value: 4,
    name: 'save_method4',
    text: '冷凍庫',
  },
  {
    key: '5',
    value: 5,
    name: 'save_method5',
    text: '保冷庫',
  },
  {
    key: '6',
    value: 6,
    name: 'save_method6',
    text: '冷暗所',
  },
];

export const saveMethodNames = saveMethod.map(({ name }) => name);

export default saveMethod;
