/* StripeElementsのDOM登録処理 */
import notify from '@/migration/notification';
import { paths } from '../config';

function registerElements(
  elements,
  elementsName,
  stripe,
  postUserCard,
  users,
  putUserCards,
  getMe,
  push
) {
  const formClass = '.' + elementsName;
  const cardElements = document.querySelector(formClass);

  const form = cardElements.querySelector('form');
  const error = form.querySelector('.error');
  const errorMessage = error.querySelector('.message');

  function enableInputs() {
    Array.prototype.forEach.call(
      form.querySelectorAll(
        "input[type='text'], input[type='email'], input[type='tel']"
      ),
      function (input) {
        input.removeAttribute('disabled');
      }
    );
  }

  function disableInputs() {
    Array.prototype.forEach.call(
      form.querySelectorAll(
        "input[type='text'], input[type='email'], input[type='tel']"
      ),
      function (input) {
        input.setAttribute('disabled', 'true');
      }
    );
  }

  const savedErrors = {};
  elements.forEach(function (element, idx) {
    element.on('change', function (event) {
      if (event.error) {
        error.classList.add('visible');
        savedErrors[idx] = event.error.message;
        errorMessage.innerText = event.error.message;
      } else {
        savedErrors[idx] = null;

        const nextError = Object.keys(savedErrors)
          .sort()
          .reduce(function (maybeFoundError, key) {
            return maybeFoundError || savedErrors[key];
          }, null);

        if (nextError) {
          errorMessage.innerText = nextError;
        } else {
          error.classList.remove('visible');
        }
      }
    });
  });

  form.addEventListener('submit', async (e) => {
    e.preventDefault();

    cardElements.classList.add('submitting');
    disableInputs();

    try {
      const result = await stripe.createToken(elements[0]);

      if (!result.token) {
        throw new Error();
      }

      if (window.isAuthed) {
        const userId = users.mydata.id.toString();

        try {
          // 登録済みであるか
          if (users.mydata.isCustomer) {
            await putUserCards(userId, result.token.id);
          } else {
            await postUserCard(userId, result.token.id);
          }
        } catch (err) {
          throw new Error(err);
        }
        await getMe();
        cardElements.classList.remove('submitting');
        cardElements.classList.add('submitted');

        const targetLocation = paths.mypage.credit_register;
        setTimeout(() => {
          const currentLocation = window.location.pathname;
          if (targetLocation === currentLocation) {
            push(paths.mypage.credit);
          }
        }, 3000);
      } else {
        cardElements.classList.remove('submitting');
        cardElements.classList.add('submitted');
        enableInputs();
      }
    } catch (err) {
      push(paths.mypage.credit);
      notify('クレジットカード情報に誤りがあります。', 'error', undefined, 5);
      enableInputs();
    }
  });
}

export default registerElements;
