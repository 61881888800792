import React from 'react';

const MediaPublication = () => {
  return (
    <div className="media">
      <div className="media-header">メディア掲載</div>
      <div className="media-content">
        <ul>
          {[...Array(38)].map((_, i) => (
            <li key={i}>
              <img
                src={`https://vegetablesale.jp/assets/publication_media/media-logo${
                  i + 1
                }.jpg`}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MediaPublication;
