import ikejimeMethods, {
  ikejimeMethodNames,
} from '../../../config/app/ikejimeMethod';
import cleaningMethods, {
  cleaningMethodNames,
} from '../../../config/app/cleaningMethod';
import savingMethods, {
  savingMethodNames,
} from '../../../config/app/savingMethod';
import shippingExpenses, {
  shippingExpenseNames,
} from '../../../config/app/shippingExpense';
import deliveryMethods, {
  deliveryMethodNames,
} from '../../../config/app/deliveryMethod';
import shippingTimings, {
  shippingTimingNames,
} from '../../../config/app/shippingTiming';
import ikejimeTimings from '../../../config/app/ikejimeTiming';

// 追加
import grade, { gradeNames } from '@/config/app/grade';
import cultivationMethod, {
  cultivationMethodNames,
} from '@/config/app/cultivationMethod';
import soil, { soilNames } from '@/config/app/soil';
import pesticides, { pesticidesNames } from '@/config/app/pesticides';
import saveMethod, { saveMethodNames } from '@/config/app/saveMethod';
import genetic, { geneticNames } from '@/config/app/genetic';
import jas, { jasNames } from '@/config/app/jas';

// TODO:送る値修正
const whiteListKeys = [
  'min_size',
  'max_size',
  'min_weight',
  'max_weight',
  'min_price',
  'max_price',
  'min_fix_price',
  'max_fix_price',
  'shipping_timing',
  'is_new',
  'is_immediately',
  'is_nature',
  'keyword',
  'status',
  'area',
  'sort',
  // 追加
  'category',
  'breed',
  'grade',
  'rank',
];

export const getRequestParameter = (values) => {
  const query = {};

  const ikejimeMethod = [];
  const cleaningMethod = [];
  const savingMethod = [];
  const shippingExpense = [];
  const deliveryMethod = [];
  const shippingTiming = [];
  const ikejimeTiming = [];

  // 追加
  const grades = [];
  const cultivationMethods = [];
  const soils = [];
  const pesticidess = [];
  const saveMethodSellers = [];
  const saveMethodUsers = [];
  const genetics = [];
  const jass = [];

  Object.entries(values).forEach(([key, value]) => {
    // 追加
    if (gradeNames.includes(key)) {
      if (value) {
        const found = grade.find(({ name }) => name === key);
        if (found) grades.push(found.value);
      }
    }

    if (cultivationMethodNames.includes(key)) {
      if (value) {
        const found = cultivationMethod.find(({ name }) => name === key);
        if (found) cultivationMethods.push(found.value);
      }
    }

    if (soilNames.includes(key)) {
      if (value) {
        const found = soil.find(({ name }) => name === key);
        if (found) soils.push(found.value);
      }
    }

    if (pesticidesNames.includes(key)) {
      if (value) {
        const found = pesticides.find(({ name }) => name === key);
        if (found) pesticidess.push(found.value);
      }
    }

    if (saveMethodNames.includes(key)) {
      if (value) {
        const found = saveMethod.find(({ name }) => name === key);
        if (found) saveMethodSellers.push(found.value);
      }
    }

    if (saveMethodNames.includes(key)) {
      if (value) {
        const found = saveMethod.find(({ name }) => name === key);
        if (found) saveMethodUsers.push(found.value);
      }
    }

    if (geneticNames.includes(key)) {
      if (value) {
        const found = genetic.find(({ name }) => name === key);
        if (found) genetics.push(found.value);
      }
    }

    if (jasNames.includes(key)) {
      if (value) {
        const found = jas.find(({ name }) => name === key);
        if (found) jass.push(found.value);
      }
    }

    // ここまで追加

    if (ikejimeMethodNames.includes(key)) {
      if (value) {
        const found = ikejimeMethods.find(({ name }) => name === key);

        if (found) ikejimeMethod.push(found.value);
      }
    }

    if (cleaningMethodNames.includes(key)) {
      if (value) {
        const found = cleaningMethods.find(({ name }) => name === key);

        if (found) cleaningMethod.push(found.value);
      }
    }

    if (savingMethodNames.includes(key)) {
      if (value) {
        savingMethod.push(savingMethods.find(({ name }) => name === key).value);
      }
    }

    if (shippingExpenseNames.includes(key)) {
      if (value) {
        const found = shippingExpenses.find(({ name }) => name === key);

        if (found) shippingExpense.push(found.value);
      }
    }

    if (deliveryMethodNames.includes(key)) {
      if (value) {
        const found = deliveryMethods.find(({ name }) => name === key);

        if (found) deliveryMethod.push(found.value);
      }
    }

    if (shippingTimingNames.includes(key)) {
      if (value) {
        const found = shippingTimings.find(({ name }) => name === key);

        if (found) shippingTiming.push(found.value);
      }
    }

    if (key === 'ikejime_timing') {
      if (value) {
        const found = ikejimeTimings.find(({ text }) => text === value);

        if (found) ikejimeTiming.push(found.value);
      }
    }

    if (whiteListKeys.includes(key)) {
      if (value) {
        query[key] = value;
      }
    }
  });

  if (ikejimeMethod.length) {
    query['ikejime_method'] = ikejimeMethod;
  }

  if (cleaningMethod.length) {
    query['cleaning_method'] = cleaningMethod;
  }

  if (savingMethod.length) {
    query['saving_method'] = savingMethod;
  }

  if (shippingExpense.length) {
    query['shipping_expense'] = shippingExpense;
  }

  if (deliveryMethod.length) {
    query['delivery_method'] = deliveryMethod;
  }

  if (shippingTiming.length) {
    query['shipping_timing'] = shippingTiming;
  }

  if (ikejimeTiming.length) {
    query['ikejime_timing'] = ikejimeTiming;
  }

  // 追加
  if (grades.length) {
    query['grade'] = grades;
  }

  if (cultivationMethods.length) {
    query['cultivation_method'] = cultivationMethods;
  }

  if (soils.length) {
    query['soil'] = soils;
  }

  if (pesticidess.length) {
    query['pesticides'] = pesticidess;
  }

  if (saveMethodSellers.length) {
    query['save_method_seller'] = saveMethodSellers;
  }

  if (saveMethodUsers.length) {
    query['save_method_user'] = saveMethodUsers;
  }

  if (genetics.length) {
    query['genetic'] = genetics;
  }

  if (jass.length) {
    query['jas'] = jass;
  }

  return { query };
};
