import * as React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Field } from 'redux-form/immutable';
import { Button, Image } from 'semantic-ui-react';
import { DivLiner, TitleBar } from '@/components/Common';
import { assets, paths } from '../../../config';
import { FieldInput } from '../../Form';
import enhance from './enhancer';
import { Header } from '../../Layouts/Header';
import { Footer } from '../../Layouts/Footer';

function Signin(props) {
  const {
    signIn,
    federation,
    handleSubmit,
    isLoading,
    displayLoginError,
  } = props;

  return (
    <div className="app_signin flex">
      <Header />
      <div className="wrapper">
        <TitleBar title="ログイン" />

        <form className="flex" onSubmit={handleSubmit(signIn)}>
          <div className="sns_buttons flex">
            <Button
              type="button"
              className="btn_fb"
              onClick={(event) => federation(event, 'Facebook')}
              disabled={isLoading}
            >
              <div className="w__sns_signin_content">
                <div className="signin_fb">
                  <Image src={assets.icon_login_facebook} className="fb" />
                  <span className="content fb">Facebookでログインする</span>
                </div>
              </div>
            </Button>
            <Button
              type="button"
              className="btn_google"
              onClick={(event) => federation(event, 'Google')}
              disabled={isLoading}
            >
              <div className="w__sns_signin_content">
                <div className="signin_g">
                  <Image src={assets.fish_google_icon} className="g" />
                  <span className="content g">Googleでログインする</span>
                </div>
              </div>
            </Button>
          </div>

          <div className="or">
            <span>または</span>
          </div>

          <div
            className={classNames({
              'amplify-container': true,
              app_disabled: isLoading,
            })}
          >
            <div
              className={classNames({
                login_error_message: true,
                display_error: displayLoginError,
              })}
            >
              メールアドレスまたはパスワードが違います。
              <br />
              または、Google、FacebookでVegetableeSaleの会員登録済みの可能性があります。
            </div>
            <Field
              name="username"
              className="email "
              placeholder="メールアドレス"
              component={FieldInput}
              disabled={isLoading}
              type="email"
              autoCorrect="off"
              autoCapitalize="off"
              maxLength={256}
            />
            <Field
              name="password"
              className="passowrd"
              placeholder="パスワード"
              type="password"
              component={FieldInput}
              disabled={isLoading}
            />
            <Button
              loading={isLoading}
              disabled={isLoading}
              content="ログインする"
              className="submit_login"
            />
            <div className="link_forgotpassword">
              <Link to={paths.before.resetPassword}>
                パスワードをお忘れの方はこちらから
              </Link>
            </div>
            <div className="liner flex bottom">
              <DivLiner className="div_liner flex" width="100%" height="1" />
            </div>
          </div>
        </form>

        <footer
          className={classNames({
            flex: true,
            app_disabled: isLoading,
          })}
        >
          <span className="attention_signup">
            アカウントをお持ちではないですか？
            <Link to={paths.before.signup} className="signupLink">
              新規登録
            </Link>
          </span>
        </footer>
      </div>
      <Footer />
    </div>
  );
}

export default enhance((props) => <Signin {...props} />);
