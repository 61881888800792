/* @flow */
import statusCategory from './statusCategory';
import images from './images';
import privacy from './privacy';
import terms from './terms';
import prices from './prices';

const DOMAIN = 'vegetablesale.jp';

export default {
  fishes: {
    statusCategory,
  },
  images,
  logo: `https://${DOMAIN}/assets/logo/logo@2x.png`,
  logoVertical: `https://${DOMAIN}/assets/logo/logo_vertical_tm.png`,
  logo_inverted: `https://${DOMAIN}/assets/b2%403x+(1).png`,
  logo_inverted_vertical_tm: `https://${DOMAIN}/assets/logo/logo_inverted_vertical_tm.png`,
  heroImage_top_mobile: ``,
  footer_ocean: `https://${DOMAIN}/assets/footer-ocean.jpg`,
  icon_magnifier: `https://${DOMAIN}/assets/icon-magnifier.png`,
  icon_privacy: `https://${DOMAIN}/assets/common-title-decoration.png`,
  icon_sns_facebook: `https://${DOMAIN}/assets/icon-facebook.png`,
  icon_login_facebook: `https://${DOMAIN}/assets/FB-f-Logo__blue_50.png`,
  icon_sns_twitter: `https://${DOMAIN}/assets/icon-twitter.png`,
  icon_sns_line: `https://${DOMAIN}/assets/icon-line.png`,
  selling_feature_smartphone_pc: `https://${DOMAIN}/assets/selling-feature-smartphone-pc.png`,
  selling_feature_dollar_pc: `https://${DOMAIN}/assets/selling-feature-dollar-pc.png`,
  selling_feature_fish_pc: `https://${DOMAIN}/assets/selling-feature-fish-pc.png`,
  selling_feature_decoration_bar: `https://${DOMAIN}/assets/selling-feature-decoration-bar.svg`,
  fish_facebook_icon: `https://${DOMAIN}/assets/facebook.png`,
  fish_instagram_icon: `https://${DOMAIN}/assets/instagram.png`,
  fish_google_icon: `https://${DOMAIN}/assets/google.png`,
  profile_header_bg: `https://${DOMAIN}/assets/profile-header-bg.jpg`,
  human_icon: `https://${DOMAIN}/assets/human-icon.png`,
  download_icon: `https://${DOMAIN}/assets/download%403x.png`,
  account_1: `https://${DOMAIN}/assets/introduction-account.png`,
  account_2: `https://${DOMAIN}/assets/introduction-account2.png`,
  account_3: `https://${DOMAIN}/assets/account_3%403x.png`,
  account_4: `https://${DOMAIN}/assets/account_4%403x.png`,
  banner_1: `https://${DOMAIN}/assets/banner_1%403x.png`,
  banner_2: `https://${DOMAIN}/assets/banner_2%403x.png`,
  banner_3: `https://${DOMAIN}/assets/banner_3%403x.png`,
  banner_4: `https://${DOMAIN}/assets/banner_4%403x.png`,
  fee_1: `https://${DOMAIN}/assets/service-fee.jpg`,
  sell_1: `https://${DOMAIN}/assets/introduction-sell.png`,
  sell_2: `https://${DOMAIN}/assets/introduction-sell2-1.png`,
  sell_3: `https://${DOMAIN}/assets/sell-guide_3%403x.png`,
  sell_4: `https://${DOMAIN}/assets/sell-guide_4%403x.png`,
  sell_5: `https://${DOMAIN}/assets/sell-guide_5%403x.png`,
  notfound_logo: `https://${DOMAIN}/assets/logo/logo_404.png`,
  policy: {
    privacy,
    terms,
  },
  delivery: {
    prices,
  },
};
