const deliveryMethods = [
  {
    key: '1',
    name: 'deliveryMethods1',
    value: 1,
    text: '常温',
  },
  {
    key: '2',
    name: 'deliveryMethods2',
    value: 2,
    text: '冷蔵',
  },
  {
    key: '3',
    name: 'deliveryMethods3',
    value: 3,
    text: '冷凍',
  },
];

export const deliveryMethodNames = deliveryMethods.map(({ name }) => name);

export default deliveryMethods;
