export default [...Array(11)].map((_, v) => {
  const date = new Date();
  date.setDate(date.getDate() + v);

  return {
    key: v + 1,
    value: v + 1,
    text: date.getMonth() + 1 + '月' + date.getDate() + '日',
  };
});
