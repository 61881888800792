import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Field, FieldArray } from 'redux-form/immutable';
import { Button, Divider, Header, Image } from 'semantic-ui-react';
import { Dropdown, FormLabelIcon, TitleBar } from '@/components/Common';
import {
  FieldInput,
  FieldTextArea,
  FieldLabelLinkCheckbox,
} from '../../../Form';
import enhance from './enhance';
import { prefecture } from './utils';
import Myshippingdata from './Myshippingdata';
import Modal from '@/migration/modal';
import { ResetPasswordModal } from './ResetPasswordModal';
import { ResetEmailModal } from './ResetEmailModal';
import { Link } from 'react-router-dom';
import { paths } from '@/config';

function MyPageEdit(props) {
  const {
    submitProfile,
    handleSubmit,
    viewSaleImage,
    drop,
    submitting,
    isImageLoading,
    openResetEmailModal,
    openResetPasswordModal,
    updateOpenEmailModal,
    updateOpenPasswordModal,
    form: {
      myPageEditForm: { values },
    },
    users: { mydata },
    change,
    deleteShippingData,
    searchAddress,
  } = props;
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: drop,
  });
  const ids = values ? values.myshippingdata.map(({ id }) => id) : [];

  return (
    <div>
      <TitleBar title="プロフィール編集" />

      <div className="a__edit_mypage">
        <div className="mypage_contents">
          <div className="member_information_container">
            <form className="flex" onSubmit={handleSubmit(submitProfile)}>
              <div className="signup_registration_form">
                <div className="introduction_container">
                  <Header as="h2">
                    自己紹介文
                    <span className="word_count">(最大1,000文字)</span>
                    <FormLabelIcon value="任意" optional />
                  </Header>

                  <Divider />
                  <Field
                    rows={3}
                    name="introduction"
                    maxLength={1001}
                    component={FieldTextArea}
                    onChange={() => {
                      change('confirm_address', false);
                    }}
                  />
                </div>

                {/* 会員情報 */}
                <div className="sub_title">
                  <Header as="h2">会員情報</Header>
                </div>
                <Divider />

                <div className="form_item">
                  <div className="company_explanation">
                    <p>
                      事業者としてVegetableSaleを利用する場合、下記のチェックボックスにチェックしてください。事業者として利用しない場合は、チェックの必要はありません。
                    </p>
                  </div>
                  <div className="flex_required optional">
                    <Field
                      name="isCompany"
                      component={FieldLabelLinkCheckbox}
                      id="isCompany"
                      children={
                        <div className="is_company">
                          <span className="checkbox_label">
                            事業者として特定商取引法を遵守します(特定商取引法は
                            <a
                              href="https://www.caa.go.jp/policies/policy/consumer_transaction/specified_commercial_transactions/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              こちら
                            </a>
                            )
                          </span>
                          <FormLabelIcon value="任意" optional />
                        </div>
                      }
                    />
                  </div>
                  <div className="company_explanation_notice">
                    ※事業者としてのご利用は出品数の制限が免除されます。
                    <br />
                    ※また、事業者としてのご利用はプロフィール、商品ページにニックネームではなく、事業者名が表示されるようになります。
                  </div>
                  {values && values.isCompany ? (
                    <React.Fragment>
                      <div className="flex_required">
                        <label>正しい事業者名</label>
                        <span className="word_count">(最大20文字)</span>
                        <FormLabelIcon value="必須" />
                      </div>
                      <Field
                        name="companyName"
                        className="companyName"
                        placeholder="例) ベジタブルカンパニー"
                        component={FieldInput}
                        onChange={() => {
                          change('confirm_address', false);
                        }}
                        maxLength={21}
                      />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className="flex_required">
                        <label>ニックネーム</label>
                        <FormLabelIcon value="必須" />
                      </div>
                      <Field
                        name="displayName"
                        className="display_name"
                        placeholder={mydata.displayName}
                        component={FieldInput}
                        onChange={() => {
                          change('confirm_address', false);
                        }}
                        maxLength={21}
                      />
                    </React.Fragment>
                  )}

                  {values && values.isCompany ? null : (
                    <div className="form_item">
                      <div className="flex_required">
                        <label>都道府県</label>
                        <FormLabelIcon value="必須" />
                      </div>
                      <Field
                        search
                        fluid
                        className="prefecture"
                        name="prefecture"
                        component={Dropdown}
                        onChange={() => {
                          change('confirm_address', false);
                        }}
                        placeholder="都道府県"
                        options={prefecture}
                        noResultsMessage="該当する都道府県はありません"
                      />
                    </div>
                  )}

                  <div className="form_item">
                    {values && values.isCompany ? (
                      <React.Fragment>
                        <div className="flex_required">
                          <label>住所</label>
                          <FormLabelIcon value="必須" />
                        </div>
                        <Field
                          name="companyAddress"
                          className="consignee_address"
                          placeholder="例) 大阪府大阪市西区１−２ ベジタブルビル3F"
                          component={FieldInput}
                          onChange={() => {
                            change('confirm_address', false);
                          }}
                          maxLength={51}
                        />
                      </React.Fragment>
                    ) : null}
                  </div>

                  {/* <div className="form_item">
                    <div className="flex_required">
                      <label>よく利用する釣船</label>
                      <FormLabelIcon value="任意" optional />
                    </div>
                    <Field
                      name="fishingBoat"
                      className="fishing_boat"
                      placeholder="例) 商品釣丸"
                      component={FieldInput}
                      onChange={() => {
                        change('confirm_address', false);
                      }}
                      maxLength={21}
                    />
                  </div> */}

                  <div className="form_item">
                    {values && values.isCompany ? (
                      <div className="form_item">
                        <div className="flex_required">
                          <label>使用配送業者</label>
                          <FormLabelIcon value="必須" />
                        </div>
                        <Field
                          fluid
                          className="prefecture"
                          name="shippingProvider"
                          component={Dropdown}
                          onChange={() => {
                            change('confirm_address', false);
                          }}
                          placeholder="使用配送業者"
                          options={[
                            { value: 1, text: '佐川急便' },
                            { value: 2, text: 'ヤマト運輸' },
                            { value: 3, text: '日本郵便' },
                          ]}
                        />
                      </div>
                    ) : null}
                  </div>

                  <div className="sub_title">
                    <Header as="h2">メールアドレス・パスワードの変更</Header>
                  </div>
                  <div className="change_email_caption">
                    <span>【重要】</span>
                    新規登録時にGoogleまたはFacebookで登録されていない方のみ変更可能です。
                    <br />
                    GoogleやFacebookでログインしている場合、VegetableeSaleではメールアドレス・パスワードの変更はできません。
                  </div>

                  <div className="change_email">
                    <Button
                      type="button"
                      onClick={() => updateOpenEmailModal(true)}
                      fluid
                    >
                      メールアドレスを変更する
                    </Button>
                  </div>
                  <Modal
                    className="reset_email_modal"
                    isModal={openResetEmailModal}
                    handleCloseModal={() => {
                      updateOpenEmailModal(false);
                    }}
                    loading={props.loading}
                    ModalBody={ResetEmailModal}
                    resetEmail={props.resetEmailSubmit}
                  />

                  <div className="change_password">
                    <Button
                      type="button"
                      onClick={() => updateOpenPasswordModal(true)}
                      fluid
                    >
                      パスワードを変更する
                    </Button>
                  </div>
                  <Modal
                    className="reset_password_modal"
                    isModal={openResetPasswordModal}
                    history={props.history}
                    handleCloseModal={() => {
                      updateOpenPasswordModal(false);
                    }}
                    loading={props.loading}
                    resetPassword={props.resetPassword}
                    ModalBody={ResetPasswordModal}
                  />

                  <div className="sub_title">
                    <Header as="h2">
                      自己紹介画像
                      <FormLabelIcon value="任意" optional />
                    </Header>
                    <Divider />
                  </div>
                  <div className="form_item">
                    <div className="dropzone flex" {...getRootProps()}>
                      <input {...getInputProps()} />
                      {viewSaleImage ? (
                        <Image src={viewSaleImage} size="medium" />
                      ) : (
                        <div className="small_font">
                          <span className="dropzone_pc">
                            ドラッグ&ドロップ
                            <br />
                            またはクリックして画像をアップロード
                          </span>
                          <span className="dropzone_mobile">
                            画像をアップロード
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* 落札時確認事項スキップ設定 */}
                {/* FIXME コンポーネント切り分け */}
                <div className="sub_title">
                  <Header as="h2">落札時確認事項スキップ設定</Header>
                </div>
                <Divider />
                <div className="mypage-edit__skip-config">
                  <ul>
                    <li>
                      入札する商品が絶滅危惧種に該当しないことを確認し、入札する。
                      <br />
                      <a
                        href="http://www.env.go.jp/press/files/jp/114457.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        絶滅危惧種リストはこちら
                      </a>
                    </li>
                    <li>
                      入札する商品が国内希少種に該当しないことを確認し、入札する。
                      <br />
                      <a
                        href="https://www.env.go.jp/nature/kisho/domestic/list.html#gyorui"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        国内希少種リストはこちら
                      </a>
                    </li>
                    <li>
                      佐川急便を利用した元払い配送で配属先が離島の場合、決済時に別途離島中継料金が加算されることを理解し、入札する。
                      <br />
                      <a
                        href={`${paths.common.fee}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        対象地域と料金はこちら
                      </a>
                    </li>
                  </ul>

                  <div className="flex_required">
                    <Field
                      name="isBidPopup"
                      component={FieldLabelLinkCheckbox}
                      id="is_bid_popup"
                      children="上記項目に同意し、入札時の確認を省略する。"
                    />
                  </div>
                </div>

                {/* 決済に関する設定 */}
                <div className="sub_title">
                  <Header as="h2">決済に関する設定</Header>
                </div>
                <Divider />

                <div className="form_item flex_container">
                  <div className="flex_required">
                    <Field
                      name="isNextAutoBid"
                      component={FieldLabelLinkCheckbox}
                      id="is_next_auto_bid"
                      children={`最高価格落札者が決済エラー（登録カード不備など）となり、落札権利が自分に移った場合、入札していた金額で落札する。`}
                      annotation={
                        '※ 利用規約（第11条第3項）では次点入札者が落札することが前提となっています。'
                      }
                    />
                  </div>
                </div>

                {/* 落札した商品の送付先 */}
                <div className="sub_title">
                  <Header as="h2">落札した商品の送付先</Header>
                </div>
                <Divider />
              </div>
              <div className="form_item flex_container">
                <span>
                  落札した商品の送付先情報を入力しておくと、落札後、出品者にあなたの住所が表示されます。
                </span>
                <br />
              </div>
              <p className="address_note">
                ※送付先を入力する場合は、送付先名、郵便番号、住所、携帯電話番号、配達指定時間をすべて入力してください。
              </p>
              <p className="address_note">
                ※送付先情報に変更があった場合は対象の送付先を一度削除の後、もう一度登録してください。
              </p>
              {mydata.isCustomer == 0 ? (
                <p className="w__reco_credit">
                  <span>【重要】</span>
                  入札する場合クレジットカード・デビットカードの
                  <Link to={paths.mypage.credit}>登録</Link>
                  も必要です。
                  <br />
                </p>
              ) : null}
              <div className="form_validation" />

              <FieldArray
                component={Myshippingdata}
                name="myshippingdata"
                ids={ids}
                change={change}
                deleteShippingData={deleteShippingData}
                searchAddress={searchAddress}
              />

              <div className="flex_required">
                <Field
                  name="confirm_address"
                  component={FieldLabelLinkCheckbox}
                  id="confirm_address"
                  children={
                    <div className="confirm_address">
                      <span className="checkbox_label">
                        正しい情報を入力している
                      </span>
                    </div>
                  }
                />
              </div>
              <div>
                <p className="address_note" style={{ marginTop: '8px' }}>
                  ※間違った情報を入力し、商品が届かない場合、Vegetable
                  Saleは責任を負いません。
                </p>
              </div>

              <Button
                loading={submitting}
                disabled={submitting || isImageLoading}
                content="登録する"
                className="btn_submit"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default enhance((props) => <MyPageEdit {...props} />);
