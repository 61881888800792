const endpoint = 'https://vegetablesale.jp';

export default {
  securityCode: endpoint + '/assets/security-code.png',
  banner_1: endpoint + '/assets/logo/banner_white@140x140.jpg',
  banner_2: endpoint + '/assets/logo/banner_green@140x140.jpg',
  banner_3: endpoint + '/assets/logo/banner_white@275x60.jpg',
  banner_4: endpoint + '/assets/logo/banner_green@275x60.jpg',
  logo_header: endpoint + '/assets/logo/logo.png',
  logo_header_mobile: endpoint + '/assets/logo/logo-header-mobile.png',
  logo_footer: endpoint + '/assets/logo/logo-white.png',
  facebookLogo: endpoint + '/assets/logo/logo%40facebook.png',
  googleLogo: endpoint + '/assets/logo/logo%40google.png',
  stripeLogo: endpoint + '/assets/logo/logo%40stripe.png',
};
