import * as React from 'react';

import { MediaPublication } from '@/components/Common';

function Intro() {
  return (
    <>
      <div className="mainvisual">
        <div className="landing-background">
          <div className="mainvisual-inner">
            <div className="content-inner">
              <div className="balloon">
                <span className="bg-balloon">世界初!?</span>
              </div>
              <div className="section-inner">
                <div className="content-width">
                  <div className="sub-txt">
                    <span className="slanting-line more_than_md">\ </span>
                    <span>
                      誰でも簡単に
                      <span className="less_than_sm">
                        <br />
                      </span>
                      <span className="slanting-line less_than_sm">\ </span>
                      売ったり買ったりを楽しめる
                    </span>
                    <span className="slanting-line"> /</span>
                  </div>
                  <h2 className="catch-txt">
                    野菜オークション
                    <span className="less_than_sm">
                      <br />
                    </span>
                    スタート！！
                  </h2>
                  <div className="mv-merit-list-floatlist">
                    <div className="floatlist-pig">
                      <span className="floatlist-text">安い</span>
                    </div>
                    <div className="floatlist-good">
                      <span className="floatlist-text">簡単</span>
                    </div>
                    <div className="floatlist-heart">
                      <span className="floatlist-text">安心</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MediaPublication />

      <div className="sec-trouble">
        <div className="section-inner">
          <div className="content-width">
            <div className="first-content">
              <div className="sec-ttl">
                こんな
                <div className="bg-circle">
                  <div className="bg-circle-inner">お悩み</div>
                </div>
                <span className="more_than_md">ございませんか？</span>
              </div>
              <div className="less_than_sm">ございませんか？</div>
            </div>

            <div className="sec-content">
              <h3 className="content-ttl">
                専門店、野菜農家から飲食店やスーパーの方まで
              </h3>
              <div className="trouble-list">
                <div className="trouble-card">
                  <div className="img-box">
                    <img
                      src="https://vegetablesale.jp/assets/trouble_list_img_01.jpg"
                      loading="lazy"
                      decoding="async"
                    />
                  </div>
                  <div className="txt-box">
                    <h4 className="ttl">八百屋のOさん</h4>
                    <p className="txt">
                      物価の変動によって、お客様が店頭に足を運んでくれなくなることが多々あります。品質の高い野菜をたくさん揃えているので、もっとお客様に手に取っていただきたいです。
                    </p>
                  </div>
                </div>

                <div className="trouble-card">
                  <div className="img-box">
                    <img
                      src="https://vegetablesale.jp/assets/trouble_list_img_02.jpg"
                      alt="野菜農家のSさん"
                      loading="lazy"
                      decoding="async"
                    />
                  </div>
                  <div className="txt-box">
                    <h4 className="ttl">野菜農家のSさん</h4>
                    <p className="txt">
                      野菜は店頭での販売日数が短いので、せっかく作った多くの野菜が廃棄・返品されることがよくあります。仕方ないことだとは思いながら、どうにか色々な食卓に届けられないかなといつも思っています…。
                    </p>
                  </div>
                </div>

                <div className="trouble-card">
                  <div className="img-box">
                    <img
                      src="https://vegetablesale.jp/assets/trouble_list_img_03.jpg"
                      alt="飲食店を営むMさん"
                      loading="lazy"
                      decoding="async"
                    />
                  </div>
                  <div className="txt-box">
                    <h4 className="ttl">飲食店を営むMさん</h4>
                    <p className="txt">
                      新型コロナウイルス感染症の影響で売上が減った分、どうにかして仕入れ価格を抑えたい…。
                      <br />
                      飲食店を経営している人は皆さんそう思っているはずです。
                    </p>
                  </div>
                </div>

                <div className="trouble-card">
                  <div className="img-box">
                    <img
                      src="https://vegetablesale.jp/assets/trouble_list_img_04.jpg"
                      alt="料理好きの主婦Hさん"
                      loading="lazy"
                      decoding="async"
                    />
                  </div>
                  <div className="txt-box">
                    <h4 className="ttl">料理好きの主婦Hさん</h4>
                    <p className="txt">
                      料理が大好きなので毎日ご飯を作るのですが、食費が結構かかっています。できるだけ費用は抑えたいのですが、野菜は欠かせないのでなかなか節約できません。できるだけ安く、でも新鮮な野菜が買えたら嬉しいです。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-inner">
        <div className="ttl-container">
          <div className="content-width-title">
            <div className="ttl-box">
              <div className="ttl-subtxt">
                <div className="content-titile">
                  <span className="slanting-line">\ </span>
                  そんな要望を叶えるのが世界初！？
                  <span className="slanting-line"> /</span>
                </div>
              </div>
              <div className="ttl">
                野菜のオークションサイト
                <span className="less_than_sm">
                  <br />
                </span>
                <span className="emphasis-text">”Vegetable Sale”</span>
                です。
              </div>
            </div>
          </div>
        </div>

        <div className="content">
          <div className="content-width-intro">
            <div className="sec-ttl">
              Vegetable Saleは、野菜を
              <span className="under-line">その場で出品</span>して、
              <span className="more_than_md">
                <br />
              </span>
              欲しい時に<span className="under-line">欲しい人に届ける</span>
              <span className="less_than_md">
                <br />
              </span>
              野菜専門のオークションサイトです。
            </div>
            <div className="common-box-container">
              <div className="exhibitor-merit-box">
                <div className="header-yellow">出品者のメリット</div>
                <div className="lower">
                  <div className="merit-list">
                    <div className="merit-first">
                      <div className="catch-circle">
                        <span className="cell-intro">
                          手早く
                          <br />
                          簡単に
                          <br />
                          出品可能！
                        </span>
                      </div>
                      <p className="txt">
                        野菜の写真を撮ります。
                        <br />
                        種類やサイズなどを入力します。
                        <br />
                        それだけで出品できます。
                      </p>
                    </div>
                    <p className="txt-sm">
                      野菜の写真を撮ります。
                      <br />
                      種類やサイズなどを入力します。
                      <br />
                      それだけで出品できます。
                    </p>
                    <div className="merit-second">
                      <div className="catch-circle">
                        <span className="cell-intro">
                          出品は
                          <br />
                          もちろん
                          <br />
                          無料！
                        </span>
                      </div>
                      <p className="txt">
                        会員登録料・
                        <br />
                        月会費・カード手数料は
                        <br />
                        一切かかりません。
                      </p>
                    </div>
                    <p className="txt-sm second">
                      会員登録料・
                      <br />
                      月会費・カード手数料は
                      <br />
                      一切かかりません。
                    </p>
                  </div>
                </div>
              </div>
              <div className="bidder-merit-common-box">
                <div className="header-orange">落札者のメリット</div>
                <div className="lower">
                  <div className="bidder-merit-list">
                    <div className="merit-first">
                      <div className="catch-circle">
                        <span className="cell-intro">
                          新鮮な
                          <br />
                          野菜が
                          <br />
                          いっぱい
                        </span>
                      </div>
                      <p className="txt">
                        出品される野菜は収穫したてなので
                        <br />
                        鮮度バツグンです。
                        <br />
                        また珍しい野菜が
                        <br />
                        出品されることもあります。
                      </p>
                    </div>
                    <p className="txt-sm">
                      出品される野菜は収穫したてなので
                      <br />
                      鮮度バツグンです。
                      <br />
                      また珍しい野菜が
                      <br />
                      出品されることもあります。
                    </p>
                    <div className="merit-second">
                      <div className="catch-circle">
                        <span className="cell-intro">
                          いつでも
                          <br />
                          商品が届く
                        </span>
                      </div>
                      <p className="txt">
                        思うように野菜を仕入れることが
                        <br />
                        できなかったお店でも新鮮な野菜を
                        <br />
                        いつでも仕入れることが可能です。
                      </p>
                    </div>
                    <p className="txt-sm second">
                      思うように野菜を仕入れることが
                      <br />
                      できなかったお店でも新鮮な野菜を
                      <br />
                      いつでも仕入れることが可能です。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sec-steps">
        <div className="section-inner">
          <div className="content-width">
            <div className="sec-ttl">
              オークション出品は、
              <span className="less_than_sm">
                <br />
              </span>
              かんたん<span className="number">４</span>
              ステップ！
            </div>
            <div className="common-box-container">
              <div className="exhibitor-steps">
                <div className="header-yellow">出品者</div>
                <div className="lower">
                  <div className="steps-card">
                    <div className="steps-list-first">
                      <div className="img-box">
                        <img
                          src="https://vegetablesale.jp/assets/steps_exhibitor_list_img_01.png"
                          loading="lazy"
                          decoding="async"
                        />
                      </div>
                      <h3 className="ttl">出品する</h3>
                      <p className="txt">
                        <span>
                          売りたい商品の情報を
                          <br />
                          入力して、かんたん出品！
                        </span>
                      </p>
                    </div>

                    <div className="steps-list">
                      <div className="img-box">
                        <img
                          src="https://vegetablesale.jp/assets/steps_exhibitor_list_img_02.png"
                          loading="lazy"
                          decoding="async"
                        />
                      </div>
                      <h3 className="ttl">入札を待つ</h3>
                      <p className="txt">
                        <span>
                          入札があります。
                          <br />
                          終了するまで待ちます。
                        </span>
                      </p>
                    </div>
                    <div className="steps-list">
                      <div className="img-box">
                        <img
                          src="https://vegetablesale.jp/assets/steps_exhibitor_list_img_03.png"
                          loading="lazy"
                          decoding="async"
                        />
                      </div>
                      <h3 className="ttl">落札される</h3>
                      <p className="txt">
                        <span>
                          落札されたら、
                          <br />
                          落札者の支払いを待ちます。
                        </span>
                      </p>
                    </div>
                    <div className="steps-list last">
                      <div className="img-box">
                        <img
                          src="https://vegetablesale.jp/assets/steps_exhibitor_list_img_04.png"
                          loading="lazy"
                          decoding="async"
                        />
                      </div>
                      <h3 className="ttl">商品を発送する</h3>
                      <p className="txt">
                        <span>商品を梱包して、発送しましょう。</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="common-box-bidder-steps">
                <div className="header-orange">落札者</div>
                <div className="lower">
                  <div className="steps-card">
                    <div className="bidder-steps-list-first">
                      <div className="img-box">
                        <img
                          src="https://vegetablesale.jp/assets/steps_bidder_list_img_01.png"
                          loading="lazy"
                          decoding="async"
                        />
                      </div>
                      <h3 className="ttl">商品を探す</h3>
                      <p className="txt">
                        <span>
                          産地やサイズをみて
                          <br />
                          欲しい商品を探します。
                        </span>
                      </p>
                    </div>

                    <div className="bidder-steps-list">
                      <div className="img-box">
                        <img
                          src="https://vegetablesale.jp/assets/steps_bidder_list_img_02.png"
                          loading="lazy"
                          decoding="async"
                        />
                      </div>
                      <h3 className="ttl">入札・落札</h3>
                      <p className="txt">
                        <span>
                          希望金額で入札をします。
                          <br />
                          オークション終了時に、
                          <br />
                          あなたの入力した金額が
                          <br />
                          一番高ければ、あなたが
                          <br />
                          落札者となります。
                        </span>
                      </p>
                    </div>

                    <div className="bidder-steps-list">
                      <div className="img-box">
                        <img
                          src="https://vegetablesale.jp/assets/steps_bidder_list_img_03.png"
                          loading="lazy"
                          decoding="async"
                        />
                      </div>
                      <h3 className="ttl">代金を支払う</h3>
                      <p className="txt">
                        <span>
                          落札したら、出品者へ代金を
                          <br />
                          お支払いします。
                        </span>
                      </p>
                    </div>

                    <div className="bidder-steps-list last">
                      <div className="img-box">
                        <img
                          src="https://vegetablesale.jp/assets/steps_bidder_list_img_04.png"
                          loading="lazy"
                          decoding="async"
                        />
                      </div>
                      <h3 className="ttl">商品が届く</h3>
                      <p className="txt">
                        <span>新鮮な商品をめしあがれ！！</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Intro;
