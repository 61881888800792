import * as R from 'ramda';
import { connect } from 'react-redux';
import notify from '@/migration/notification';
import {
  compose,
  lifecycle,
  setDisplayName,
  withHandlers,
  withState,
} from 'recompose';
import { paths } from '@/config';
import { bindActionCreators } from 'redux';
import { reduxForm, getFormValues } from 'redux-form/immutable';
import { appAction, salesAction, usersAction } from '@/actions';
import { sellPreview } from '@/apis/sales/preview';
import { sell as validate } from '@/validates';
import { date, resizeDroppedImages } from '@/utils';
import { getShippingCosts, getSaleById, getSalesDetail } from '@/apis';
import qs from 'qs';
import moment from 'moment';
import soilAdditionalInfo from '@/config/app/soilAdditionalInfo';
import pesticidesAdditionalInfo from '@/config/app/pesticidesAdditionalInfo';

const mapStateToProps = (state) => {
  const formValues = getFormValues('sellForm')(state);

  return {
    router: state.router,
    users: state.users.toJS(),
    initialValues: {
      is_nature: true,
      soil: null,
      pesticides: null,
    },
    formValues: formValues ? formValues.toJS() : {},
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { ...appAction, ...salesAction, ...usersAction, notify },
    dispatch
  );

const enhance = compose(
  setDisplayName('Sell'),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'sellForm',
    initialValues: {
      // fishing_year: '1',
      // ikejime_method: 101,
      // ikejime_water: 0,
    },
    enableReinitialize: true,
    validate,
    onSubmitFail() {
      if (document.getElementById('error')) {
        document.getElementById('error').scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'nearest',
        });
      }
      notify('入力内容をご確認ください。', 'error', undefined, 5);
    },
  }),
  withState('preSaleImage', 'uploadPreSaleImage', ''),
  withState('viewSaleImage', 'uploadViewSaleImage', ''),
  withState('isImageLoading', 'uploadIsImageLoading', false),
  withState('isSellLoading', 'isSellLoadingUpdater', false),
  withState('isLoading', 'updateLoading', true),
  withState('shippingCosts', 'setShippingCosts', {}),
  withState('modalIsOpen', 'setModalIsOpen', false),
  withState('postData', 'setPostData', {}),
  withState('previewData', 'setPreviewData', {}),
  withState('showErrorMessage', 'setShowErrorMessage', ''),
  withHandlers({
    submitBiddingRegistration: (props) => async (immutableValues) => {
      const {
        setModalIsOpen,
        setPostData,
        setPreviewData,
        isSellLoadingUpdater,
        setShowErrorMessage,
      } = props;
      const values = immutableValues.toJS();

      try {
        isSellLoadingUpdater(true);

        const images = [
          ...values.sellImages.filter(
            (sellImage) => typeof sellImage === 'string'
          ),
          ...(await resizeDroppedImages(
            values.sellImages.filter(
              (sellImage) => typeof sellImage !== 'string'
            )
          )),
        ];

        const now = new Date();

        // 期限までのN分後を計算
        const close_bid_min =
          (values.close_bid_min_date - 1) * 24 * 60 +
          (values.close_bid_min_hour - now.getHours() - 1) * 60;

        let fishingDay = '';
        if (values.fishing_year && values.fishing_month && values.fishing_day) {
          fishingDay = date.margeFisingDay(
            values.fishing_year,
            values.fishing_month,
            values.fishing_day
          );
        }

        const soilInfo = values.soil_additional_info
          ? values.soil_additional_info
              .map((e, i) => (e ? (i + 1).toString() : undefined))
              .filter((f) => f !== undefined)
              .join(',')
          : null;

        const pesticidesInfo = values.pesticides_additional_info
          ? values.pesticides_additional_info
              .map((e, i) => {
                if (values.pesticides === null) return undefined;
                switch (values.pesticides) {
                  case 2:
                    if (i === 0) {
                      return e.toString();
                    } else {
                      return undefined;
                    }
                  case 3:
                    if (i !== 0 && e) return (i + 1).toString();
                    else {
                      return undefined;
                    }
                  default:
                    return undefined;
                }
              })
              .filter((f) => f !== undefined)
              .join(',')
          : null;

        const postData = {
          price: parseInt(values.price, 10),
          fix_price: parseInt(values.fix_price, 10),
          shipping_expense: values.shipping_expense,
          size: values.size,
          weight: parseInt(values.weight, 10),
          weight_additional_info: values.weight_additional_info,
          images,
          area: values.area,
          explanation: values.explanation,
          shipping_timing: values.shipping_timing,
          close_bid_min: close_bid_min,
          delivery_method: values.delivery_method,
          // is_vacuum: values.is_vacuum,
          shipping_size: values.shipping_size,
          is_land_after_fix: values.is_land_after_fix,

          // Vegetables 追加
          name: values.name,
          breed: values.breed,
          producer: values.producer,
          category: values.category,
          grade: values.grade,
          rank: values.rank,
          origin: values.origin,
          origin_additional_info: values.origin_additional_info,
          harvest_date: fishingDay,
          harvest_timing: values.harvest_timing,
          save_method_seller: values.save_method_seller,
          save_method_user: values.save_method_user,
          save_method_user_additional_info:
            values.save_method_user_additional_info,
          cultivation_method: values.cultivation_method,
          soil: values.soil,
          soil_additional_info: soilInfo,
          pesticides: values.pesticides,
          pesticides_additional_info: pesticidesInfo,
          genetic: values.genetic,
          jas: values.jas,
          individual_obligations: values.individual_obligations,
          global_gap: values.global_gap ? '認証' : null,
        };

        const { data: confirmData } = await sellPreview(postData);

        setPostData(confirmData);
        setPreviewData(confirmData);
        setShowErrorMessage('');
        setModalIsOpen(true);
      } catch (error) {
        switch (error.message) {
          case 'contain ban words':
            notify('不適切な表現が含まれています。', 'error', undefined, 5);
            break;

          case 'number of sale limit per week exceeded':
            notify(
              '月曜～日曜の出品上限数（乱獲防止対策）を超えたため、出品できません。',
              'error',
              undefined,
              5
            );
            break;

          case 'contain inappropriate images':
          case 'contain human images':
          case 'contain denial fish images':
          case 'uncontain fish images':
            notify(
              '不適切な画像が含まれているため、出品できません。',
              'error',
              undefined,
              5
            );
            break;
        }
      } finally {
        isSellLoadingUpdater(false);
      }
    },

    confirmSubmit: (props) => async () => {
      const {
        postData,
        postSale,
        isSellLoadingUpdater,
        history,
        setShowErrorMessage,
      } = props;

      try {
        isSellLoadingUpdater(true);

        const result = await postSale(postData);

        // eslint-disable-next-line
        if (result.value.hasOwnProperty('errors')) {
          throw new Error(result.value.errors[0].message);
        }

        notify('新しく商品を出品しました', 'info', undefined, 5);
        history.push(paths.mypage.root);
      } catch (error) {
        switch (error.message) {
          case 'contain ban words': {
            setShowErrorMessage('不適切な表現が含まれています。');
            break;
          }

          case 'number of sale limit per week exceeded': {
            setShowErrorMessage(
              '月曜～日曜の出品上限数（乱獲防止対策）を超えたため、出品できません。'
            );
            break;
          }

          case 'contain inappropriate images':
          case 'contain human images':
          case 'contain denial fish images':
          case 'uncontain fish images': {
            setShowErrorMessage(
              '不適切な画像が含まれているため、出品できません。'
            );
            break;
          }
        }
      } finally {
        isSellLoadingUpdater(false);
      }
    },
  }),
  lifecycle({
    async componentDidUpdate(prevProps) {
      const { form, change } = this.props;
      const prevFishingSpot = R.path(
        ['sellForm', 'values', 'fishing_spot'],
        prevProps.form
      );
      const fishingSpot = R.path(['sellForm', 'values', 'fishing_spot'], form);

      if (prevFishingSpot && prevFishingSpot !== fishingSpot) {
        fishingSpot === 0 && change('fishing_spot', null);
      }
    },
    async componentDidMount() {
      const {
        updateLoading,
        getMe,
        change,
        location: { search },
      } = this.props;
      await getMe();
      await this.props.getUserCards(this.props.users.mydata.id);
      const shippingCosts = await getShippingCosts(this.props.users.mydata.id);
      this.props.setShippingCosts(shippingCosts);
      const { id } = qs.parse(search, { ignoreQueryPrefix: true });

      if (id) {
        const data = await getSaleById(id);
        const detail = await getSalesDetail(id);

        const detailData = detail.data;

        for (const [key, value] of Object.entries(detailData)) {
          let array = null;
          let values = [];
          switch (key) {
            // 土壌詳細
            case 'soil_additional_info':
              array = value ? value.split(',').map((e) => e) : null;
              values = array
                ? soilAdditionalInfo.map((e) => {
                    return array.includes(e.value);
                  })
                : [];
              values.unshift(undefined);
              change(key, values);
              break;

            // 農薬詳細
            case 'pesticides_additional_info':
              /* eslint-disable */
              if (detailData.pesticides === 2) {
                change(key, [value]);
                break;
              }

              if (detailData.pesticides === 3) {
                array = value ? value.split(',').map((e) => e) : null;
                values = array
                  ? pesticidesAdditionalInfo.map((e) => {
                      return array.includes(e.value);
                    })
                  : [];
                values.unshift(undefined);
                change(key, values);
                break;
              }

              change(key, value);
              /* eslint-enable */

              break;

            default:
              change(key, value);
          }
        }

        for (const [key, value] of Object.entries(data)) {
          if (key === 'fishing_day') {
            change('fishing_year', moment(value).format('YYYY'));
            change('fishing_month', moment(value).format('M'));
            change('fishing_day', moment(value).format('D'));
          } else if (key === 'start_price') {
            change('price', value);
          } else if (key === 'fix_price') {
            if (value !== 2147483647) {
              change(key, value);
            }
          } else if (key === 'images') {
            change('sellImages', value);
          } else {
            change(key, value);
          }
        }
      }

      updateLoading(false);
    },
  })
);

export default enhance;
