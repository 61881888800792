import * as React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Field } from 'redux-form/immutable';
import { Button, Image } from 'semantic-ui-react';
import ReCAPTCHA from 'react-google-recaptcha';
import { DivLiner, Dropdown, TitleBar } from '@/components/Common';
import { assets, paths } from '@/config';
import { FieldAgreement, FieldInput } from '../../Form';
import enhance from './enhancer';
import { prefecture } from './utils';
import { Header } from '../../Layouts/Header';
import { Footer } from '../../Layouts/Footer';
import './index.scss';

function SignUp(props) {
  const {
    submitFederation,
    submitSignUp,
    submitRecapcha,
    handleSubmit,
    isLoading,
    displayRecapchaError,
    saveSignUpSteps,
  } = props;

  return (
    <div className="app_signup flex">
      <Header />
      <div className="wrapper_content">
        <TitleBar title="新規会員登録" />

        <p className="caution-message">
          HTMLメール・URL記載メールの受信を拒否する設定の場合、VegetableeSaleからのメールが届かない場合があるため、設定の解除をお願い致します。
        </p>

        <form className="flex" onSubmit={handleSubmit(submitSignUp)}>
          <div className="sns_buttons flex">
            <Button
              type="button"
              className="btn_fb GA_SIGN_UP_EVENT"
              disabled={isLoading}
              onClick={(event) => submitFederation(event, 'Facebook')}
            >
              <div className="w__sns_signup_content">
                <div className="signup_fb">
                  <Image src={assets.icon_login_facebook} className="fb" />
                  <span className="content fb">Facebookで登録する</span>
                </div>
              </div>
            </Button>
            <Button
              type="button"
              className="btn_google GA_SIGN_UP_EVENT"
              disabled={isLoading}
              onClick={(event) => submitFederation(event, 'Google')}
            >
              <div className="w__sns_signup_content">
                <div className="signup_g">
                  <Image src={assets.fish_google_icon} className="g" />
                  <span className="content g">Googleで登録する</span>
                </div>
              </div>
            </Button>
          </div>

          <div className="or">
            <span>または</span>
          </div>

          <div className="amplify-container">
            <Field
              name="displayName"
              className="displayName"
              placeholder="ニックネーム"
              component={FieldInput}
              disabled={isLoading}
              maxLength={21}
            />
            <Field
              name="email"
              className="email"
              placeholder="メールアドレス"
              component={FieldInput}
              disabled={isLoading}
              type="email"
              autoCorrect="off"
              autoCapitalize="off"
              maxLength={256}
            />
            <Field
              name="password"
              className="password"
              placeholder="パスワード"
              type="password"
              component={FieldInput}
              disabled={isLoading}
            />
            <Field
              name="confirmPassword"
              className="confirm_password"
              placeholder="確認用パスワード"
              type="password"
              component={FieldInput}
              disabled={isLoading}
            />
            <Field
              search
              fluid
              className="prefecture"
              name="prefecture"
              component={Dropdown}
              placeholder="都道府県"
              options={prefecture}
              noResultsMessage="該当する都道府県はありません"
            />
            <div className="field-group recaptcha">
              <ReCAPTCHA
                sitekey="6LdU7H8cAAAAAMa2O9l5ASK7zMI1VbuqyOTzx4xW"
                onChange={submitRecapcha}
                style={{ width: '288px' }}
              />
              <div
                className={classNames('login_error_message', {
                  display_error: displayRecapchaError,
                })}
              >
                選択してください
              </div>
            </div>
            <div
              className="field-group agreement"
              onClick={saveSignUpSteps}
              role="button"
              tabIndex="0"
            >
              <Field
                name="isAgreement"
                className="is_agreement"
                disabled={isLoading}
                component={FieldAgreement}
              />
            </div>
            {/* <div className="domain_guide">
              新規会員登録時６桁の暗証番号をメールで送信致します。
              <br />
              no-reply@verificationemail.com
              <br />
              上記アドレスの受信許可をして頂きますようお願い致します。
            </div> */}
            <div className="domain_guide important">
              新規会員登録時６桁の暗証番号をメールで送信致します。
              <br />
              <span>
                info<span>@freshspeed.net</span>
              </span>
              <br />
              上記アドレスの受信許可をして頂きますようお願い致します。
            </div>
            <Button
              className="btn_google submit_login"
              loading={isLoading}
              disabled={isLoading}
            >
              <div className="w__signup_submit_btn">
                <div className="signup_mail">
                  <Image src={assets.images.icon.mail} className="email" />
                  <span className="content email GA_SIGN_UP_EVENT">
                    メールアドレスで登録する
                  </span>
                </div>
              </div>
            </Button>
            <div className="liner flex bottom">
              <DivLiner className="div_liner flex" width="100%" height="1" />
            </div>
          </div>
        </form>

        <footer
          className={classNames({
            flex: true,
            app_disabled: isLoading,
          })}
        >
          <span className="attention_signin">
            アカウントをお持ちですか？
            <Link to={paths.before.signin} className="signinLink">
              ログイン
            </Link>
          </span>
        </footer>
      </div>
      <Footer />
    </div>
  );
}

export default enhance((props) => <SignUp {...props} />);
