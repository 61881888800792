const shippingExpenses = [
  {
    key: '1',
    name: 'shippingExpenses1',
    text: '送料込み（出品者が負担）',
    value: 1,
  },
  {
    key: '2',
    name: 'shippingExpenses2',
    text: '着払い（落札者が負担）',
    value: 2,
  },
  {
    key: '3',
    name: 'shippingExpenses3',
    text: '元払い（落札者が負担）',
    value: 3,
  },
];

export const shippingExpenseNames = shippingExpenses.map(({ name }) => name);

export default shippingExpenses;
