import * as React from 'react';
import { images } from '@/config';

export default function Card() {
  return (
    <div className="a__introduction-card">
      <div className="b__main">
        <div className="w__title">
          <h3>セキュリティコードについて</h3>
        </div>
        <div className="b__section">
          <div className="w__caption">
            <h3>セキュリティコードとは</h3>
            <div />
          </div>
          <p className="w__description">
            セキュリティコードとはカードによる決済処理の際に、
            カード番号とは別のセキュリティコードを入力することで
            本人認証とし、第三者の不正利用リスクの軽減を図るものです。
          </p>
          <div className="w__card-img">
            <img src={images.securityCode} />
          </div>
        </div>
        <div className="b__section">
          <div className="w__caption">
            <h3>表示箇所</h3>
            <div />
          </div>
          <p className="w__description">
            カード裏面の署名欄に記載されている番号の後に
            記載された３桁（または４桁）の番号がセキュリティコードを指します。
          </p>
          <p className="w__caution">
            ※American
            Expressにつきましては、カード表面の番号の右上に記載されている4桁の番号がセキュリティコードを指します。
          </p>
        </div>
        <div className="b__section">
          <div className="w__caption">
            <h3>ご利用可能な決済ブランド</h3>
            <div />
          </div>
          <img
            src="https://vegetablesale.jp/assets/stripe-usable-card-brand.png"
            loading="lazy"
            decoding="async"
          />
          <p className="w__caution" />
        </div>
      </div>
    </div>
  );
}
