import React from 'react';
import { Link } from 'react-router-dom';
import { useSection } from './useSection';
import { getLocaleStringLocales, paymentMethod } from '@/utils';
import { ProductSaleInfo } from '@/modules/product';

import { paths } from '@/config';

const isText = (text) => {
  return text ? text : '-';
};

// TODO: 項目追加
const DetailSection = ({ explanation, ...others }) => {
  const {
    now,
    fishingDate,
    providerName,
    decisionPrice,
    deliveryPrice,
  } = useSection({
    fishingDay: others.fishingDay,
    shippingProvider: others.shippingProvider,
    fixPrice: others.fixPrice,
    isLandAfterFix: others.isLandAfterFix,
    area: others.area,
    prefecture: others.prefecture,
    size: others.size,
    isCompany: others.isCompany,
  });

  return (
    <div className="modal__sell-confirm__main-detail">
      <p className="modal__sell-confirm__main-description">{explanation}</p>
      <ProductSaleInfo
        sales={{
          item: {
            ...others.previewData,
            postedAt: now,
            fishingDate,
            providerName,
            decisionPrice,
            deliveryPrice,
          },
        }}
        users={{ mydata: others.mydata }}
      />
    </div>
  );
};

export default DetailSection;
