import moment from 'moment';
import { PREFECTURE } from './prefecture';
import { values } from 'lodash';

export type PRODUCT_CATEGORY_TYPE = undefined | string;

export type ProductItemDataType = {
  label?: string;
  // カテゴリーによって名称が違う場合
  labelPattern?: {
    [x: string]: string;
  };
  isSideNav?: boolean;
  // 単位
  unit?: string;
  type?: 'bool' | 'string' | 'int' | 'checkbox' | 'date' | 'time';
  // 年月日の年の範囲（現在を基準）
  dateRange?: number[];
  // チェックボックスでラベルを使用せずに表示する場合
  checkboxLabel?: string;
  show?: PRODUCT_CATEGORY_TYPE[];
  placeholder?: string;
  helperText?: string;
  required?: boolean;
  // バリデーション
  validate?: {
    // 最大文字数
    max?: number;
    unit?: string;
  };
  defaultValue?: any;
  // 以下の条件を満たせば必須に変更
  linkRequired?: {
    name: string;
    value: string | true;
  };
  // 以下の条件を満たせば表示
  linkShow?: {
    name: string;
    value: number | number[] | string | string[] | true;
    // 適応するカテゴリー
    category?: PRODUCT_CATEGORY_TYPE;
    helper?: string;
  };
  // 以下の条件を満たせばdisabledに変更
  linkDisabled?: {
    name: string;
    value: string | true;
    helper?: string;
  };
  // 以下の条件を満たせばtargetの値と同じ値に置き換える
  linkValue?: {
    name: string;
    value: string | true;
    target: string;
  };
  select?: {
    show?: PRODUCT_CATEGORY_TYPE[];
    values: (
      | {
          label?: string;
          value: string | number;
        }
      | string
    )[];
  }[];
  errorName?: string;
};

export interface ProductItemType {
  [x: string]: ProductItemDataType;
}

/**
 * ProductSale固有のデータ
 */
export const PRODUCT_ITEMS: ProductItemType = {
  // 品種名
  breed: {
    label: '品種名',
  },
  // 最小サイズ
  size: {
    label: '最小サイズ',
  },
  // 不揃い
  is_unevenness: {
    type: 'bool',
    checkboxLabel: '不揃い',
  },
  // 重量
  weight: {
    label: '重量',
    type: 'int',
    unit: 'g以上',
    validate: {
      unit: 'g',
    },
  },
  // 重量備考
  weight_additional_info: {
    placeholder: '備考',
    validate: {
      max: 50,
    },
  },
  // 糖度
  average_sugar_content: {
    label: '糖度',
    placeholder: '例) 15度',
  },
  // 入り数
  input_number: {
    label: '入り数',
    placeholder: '〇個以上',
  },
  // 訳あり
  with_defects: {
    type: 'bool',
    checkboxLabel: '訳あり',
  },
  // 生産者
  producer: {
    label: '生産者',
    placeholder: '例) 山田農園',
  },
  // 産地
  origin: {
    label: '産地',
    placeholder: '例) 山田農園',
    select: [{ values: PREFECTURE }],
    required: true,
  },
  // 産地詳細
  origin_additional_info: {
    placeholder: '詳細',
    validate: {
      max: 50,
    },
  },
  // 収穫日
  harvest_date: {
    label: '収穫日',
    type: 'date',
  },
  // 落札後収穫
  is_land_after_fix: {
    type: 'bool',
    checkboxLabel: '落札後収穫',
  },
  // 収穫タイミング
  harvest_timing: {
    label: '収穫タイミング',
    select: [
      {
        values: [
          {
            value: 1,
            label: '早朝',
          },
          {
            value: 2,
            label: '早',
          },
          {
            value: 3,
            label: '昼',
          },
          {
            value: 4,
            label: '夕',
          },
          {
            value: 5,
            label: '夜',
          },
        ],
      },
    ],
  },
  // 出品者側保管方法
  save_method_seller: {
    label: '出品者側保管方法',
    select: [
      {
        values: [
          {
            value: 1,
            label: '常温',
          },
          {
            value: 2,
            label: '保温庫',
          },
          {
            value: 3,
            label: '冷蔵庫',
          },
          {
            value: 4,
            label: '冷凍庫',
          },
          {
            value: 5,
            label: '保冷庫',
          },
          {
            value: 6,
            label: '冷暗所',
          },
        ],
      },
    ],
  },
  // ご家庭での推奨保管方法
  save_method_user: {
    label: 'ご家庭での推奨保管方法',
    select: [
      {
        values: [
          {
            value: 1,
            label: '常温',
          },
          {
            value: 2,
            label: '保温庫',
          },
          {
            value: 3,
            label: '冷蔵庫',
          },
          {
            value: 4,
            label: '冷凍庫',
          },
          {
            value: 5,
            label: '保冷庫',
          },
          {
            value: 6,
            label: '冷暗所',
          },
        ],
      },
    ],
  },
  // ご家庭での推奨保管方法 詳細
  save_method_user_additional_info: {
    placeholder: '詳細）日の当たらない涼しい場所に保管してください。',
  },
  // 栽培方法
  cultivation_method: {
    label: '栽培方法',
    select: [
      {
        values: [
          {
            value: 1,
            label: '露地',
          },
          {
            value: 2,
            label: 'ハウス(促成)',
          },
          {
            value: 3,
            label: '抑制',
          },
          {
            value: 4,
            label: '水耕',
          },
          {
            value: 5,
            label: '半',
          },
          {
            value: 6,
            label: '根域制限',
          },
        ],
      },
    ],
  },
  // 土壌
  soil: {
    label: '土壌',
    select: [
      {
        values: [
          {
            value: 1,
            label: '無肥料',
          },
          {
            value: 2,
            label: '化学肥料',
          },
          {
            value: 3,
            label: '有機肥料',
          },
          {
            value: 4,
            label: '堆肥',
          },
        ],
      },
    ],
  },
  // 堆肥
  soil_additional_info: {
    type: 'checkbox',
    linkShow: {
      name: 'soil',
      value: 4,
    },
    select: [
      {
        values: [
          {
            value: '1',
            label: '家畜ふん',
          },
          {
            value: '2',
            label: '生ごみ',
          },
          {
            value: '3',
            label: 'もみがら有機',
          },
          {
            value: '4',
            label: 'Humanure',
          },
          {
            value: '5',
            label: '厩肥',
          },
        ],
      },
    ],
  },
  // 農薬
  pesticides: {
    label: '農薬',
    select: [
      {
        values: [
          {
            value: 2,
            label: '減農薬',
          },
          {
            value: 3,
            label: '無農薬',
          },
        ],
      },
    ],
  },
  pesticides_additional_info: {
    type: 'checkbox',
    select: [
      {
        values: [
          {
            value: 1,
            label: '栽培期間中不使用',
          },
          {
            value: 2,
            label: '全農薬、節減対象農薬のみ',
          },
          {
            value: 3,
            label: '飛散農薬（有）',
          },
          {
            value: 4,
            label: '飛散農薬（無）',
          },
          {
            value: 5,
            label: '残留農薬検査結果ゼロ',
          },
        ],
      },
    ],
    linkShow: {
      name: 'pesticides',
      value: 3,
    },
  },
  'pesticides_additional_info[0]': {
    placeholder: '節減対象農薬を節減「当地比〇割減、〇〇地域比○割減）」',
    linkShow: {
      name: 'pesticides',
      value: 2,
    },
  },
  // 遺伝子組み換え
  genetic: {
    label: '遺伝子組み換え',
    select: [
      {
        values: [
          {
            value: 1,
            label: '無',
          },
          {
            value: 2,
            label: '有',
          },
        ],
      },
    ],
  },
  // 有機JAS認定
  jas: {
    label: '有機JAS認定',
    type: 'checkbox',
    select: [
      {
        values: [
          {
            value: 1,
            label: '有機',
          },
          {
            value: 2,
            label: '転換期中有機',
          },
          {
            value: 3,
            label: '無農薬栽培',
          },
          {
            value: 4,
            label: '無化学肥料栽培',
          },
          {
            value: 5,
            label: '減化学肥料',
          },
        ],
      },
    ],
  },
  // 個別的義務表示事項
  individual_obligations: {
    label: '個別的義務表示事項',
    placeholder: 'アレルゲン、消費期限又は賞味期限、添加物など',
  },
  // グローバルGAP
  global_gap: {
    label: 'グローバルGAP',
    type: 'bool',
    checkboxLabel: '認証',
  },
};

export const PRODUCT_ITEMS_DEFAULT_VALUES = (() => {
  const values = {};

  Object.keys(PRODUCT_ITEMS).forEach((k) => {
    const d = PRODUCT_ITEMS[k];
    if (d.defaultValue) {
      values[k] = d.defaultValue;
    }
  });

  return values;
})();

type CreateArrayDataOutput = {
  key: string;
  value: string | number;
  name: string;
  text: string | number;
}[];

/**
 * 選択肢のある値を返す
 * @param key
 * @returns
 */
export const CreateArrayData = (key: string): CreateArrayDataOutput => {
  const data = PRODUCT_ITEMS[key].select;
  if (!data) return [];

  const res = data.flatMap((item) => {
    const val = item.values.map((v) => (typeof v === 'string' ? v : v.value));

    return val.map((v) => ({
      key: ``,
      value: v,
      name: `${key}`,
      text: v,
    }));
  });

  return removeDuplicates(res).map((item, i) => ({
    ...item,
    key: `${i}`,
    name: `${key}${i}`,
  }));
};

const removeDuplicates = (arr: CreateArrayDataOutput) => {
  const uniqueMap = {};
  arr.forEach((obj) => {
    uniqueMap[obj.value] = obj;
  });

  const uniqueArray: CreateArrayDataOutput = Object.values(uniqueMap);
  return uniqueArray;
};

/**
 * 日付のデータの初期値を入力
 * @param values 現在の値
 * @returns
 */
export const ParseDateValue = (values: any) => {
  try {
    const res = {};

    for (const key in PRODUCT_ITEMS) {
      const d = PRODUCT_ITEMS[key];

      if (d.type === 'bool') {
        const value = values[key];
        res[key] = Boolean(value);
        continue;
      }

      if (d.type !== 'date') continue;

      const value = values[key];
      if (!value) continue;

      res[`${key}_year`] = moment(value).format('YYYY');
      res[`${key}_month`] = moment(value).format('M');
      res[`${key}_day`] = moment(value).format('D');
    }

    return res;
  } catch {
    return {};
  }
};
