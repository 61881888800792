import * as React from 'react';
import { Link } from 'react-router-dom';
import enhance from './enhancer';
import { getLocaleStringLocales } from '@/utils';
import { paymentMethod, isSeller, getDeliveryPrices } from '../../../../utils';
import { paths } from '@/config';
import { replaceTofu } from '@/utils/string';
import { useDetailItem } from '@/hooks';
import { ProductSaleInfo } from '@/modules/product';

// const INVALID_DATE = 'Invalid date';

function SaleInfoList(props) {
  const { detailData } = useDetailItem(parseInt(props.sales.item.id));

  return (
    <>
      <ProductSaleInfo
        {...props}
        sales={{ item: { ...props.sales.item, ...detailData } }}
      />
    </>
  );
}

export default enhance((props) => <SaleInfoList {...props} />);
