import { connect } from 'react-redux';
import notify from '@/migration/notification';
import storage from 'store';
import { compose, setDisplayName, withHandlers, withState } from 'recompose';
import { bindActionCreators } from 'redux';

import { changeUserEmail } from '@/apis';
import { usersAction } from '@/actions';
import { paths } from '@/config';
import { queryParams } from '@/utils';

const mapStateToProps = (state) => ({
  app: state.app.toJS(),
  users: state.users.toJS(),
  sales: state.sales.toJS(),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...usersAction, notify }, dispatch);

const enhance = compose(
  setDisplayName('ChangeEmail'),
  connect(mapStateToProps, mapDispatchToProps),
  withState('isLoading', 'setIsLoading', false),
  withState('isSubmitting', 'setIsSubmitting', false),
  withHandlers({
    submitEmail: (props) => async () => {
      try {
        props.setIsSubmitting(true);

        const userId = storage.get('userId');
        if (userId === undefined) {
          props.history.push(paths.before.signin);
          notify(
            'メールアドレスの変更に失敗しました。VegetableeSaleにログインしてからメールの「メールアドレス変更画面へすすむ」リンクを再度クリックして、「メールアドレスを変更する」をクリックしてください。',
            'error',
            undefined,
            15
          );
        } else {
          const me = await props.getMe();
          const params = queryParams.parse(window.location.search);
          const uuid = params.uuid;

          await changeUserEmail(me.value.id, uuid);
          notify(
            'メールアドレスを変更しました。ログインし直してください。',
            'info',
            undefined,
            5
          );
          setTimeout(() => {
            storage.clearAll();
            window.location.href = paths.before.signin;
          }, 1000);
        }
      } catch (error) {
        notify(
          'メールアドレスの変更に失敗しました。FacebookもしくはGoogleでログインしているユーザーのため、メールアドレスは変更できません。',
          'error',
          undefined,
          5
        );
      } finally {
        props.setIsSubmitting(false);
      }
    },
  })
);

export default enhance;
