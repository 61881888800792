import { parseUser } from './utils';
import { getEnv } from '@/utils';
import { getTrafficCookie } from '@/app/getTrafficCookie';

const { GO_ENDPOINT, SITE_TYPE } = getEnv();

export default async function postUser(data) {
  const avatar_templateurl =
    'https://vegetablesale.jp/assets/noimage_normal.png';
  const campaign = getTrafficCookie();

  try {
    const response = await fetch(`${GO_ENDPOINT}/v1/${SITE_TYPE}/users`, {
      method: 'POST',
      body: JSON.stringify({
        email: data.email,
        display_name: data.displayName,
        avatar_url: data.thumnailImageUrl || avatar_templateurl,
        prefecture: data.prefecture,
        ...campaign,
      }),
    });
    const responseJson = await response.json();
    if (responseJson.errors) {
      throw new Error(responseJson.errors[0].message);
    } else {
      return parseUser(responseJson);
    }
  } catch (error) {
    throw new Error(error);
  }
}
